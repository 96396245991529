import React, {useState} from "react";
import { RepoContext } from "../RepoContext";
import "./OpenedTabs.css";
import { Icon } from "@blueprintjs/core";
import Scrollable from "../scrollable/Scrollable3";
import * as idb from "../../db/db-functions";
import "../theme-vars.scss";

interface TabProps {
    file: any;
    activeTab: string,
    activateTab: Function,
    closeTab: Function,
    darktheme: boolean
}

const Tab = (props: TabProps) => {
    let theme = props.darktheme ? "dark" : "light";
    let classNames: string[] = ['tab-button', theme];
    const [status, setStatus] = useState('LOCAL');

    if (props.file.id === props.activeTab)
        classNames.push("active")

    let className = classNames.join(' ');

    idb.getFileStatus(props.file.id).then(r => setStatus(r));

    return (
        <button
            className={`${className} ${status.toLocaleLowerCase()}`}
            onClick={() => props.activateTab(props.file.id)}
        >
            <span style={{ overflow: "hidden", paddingRight: 8 }}>{props.file.label}</span>
            <Icon onClick={(e) => props.closeTab(props.file.id, e)} style={{ overflow: "visible" }} icon="cross" />
        </button>
    );
}

export default class OpenedTabs extends React.PureComponent<{darktheme: boolean}> {
    static contextType = RepoContext;
    render() {
        var theme = this.props.darktheme ? "dark" : "light";
        return (
            <Scrollable>
                <div className={`tab-nav ${theme}`}>

                    {this.context.tabs.map((file: any) => (
                        <Tab 
                            key={file.id}
                            file={file}
                            activeTab={this.context.activeTab}
                            activateTab={this.context.activateTab}
                            closeTab={this.context.closeTab}
                            darktheme={this.props.darktheme}
                        />
                    ))}

                </div>
            </Scrollable>
        );
    }
}
