import React from "react";
import Container from "../../components/Container";
import Loading from "../Loading";

export default class Welcome extends React.Component {
  render() {
    return (
      <Container noMargin={true} style={{ height: "calc( 100vh - 50px )", display: "grid" }}>
        <Container noMargin={true} color={2}>
          <Loading style={{paddingTop: 100}}>
            <h2>Welcome to Portacode editor</h2>
            <p>Open the top right menu beside your profile pircture and choose a repository to start coding</p>
          </Loading>
        </Container>
      </Container>
    );
  }
}