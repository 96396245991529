import { Position, Toaster, Intent } from "@blueprintjs/core";

let AppToaster = Toaster.create({
  className: "recipe-toaster",
  position: Position.BOTTOM_RIGHT,
});

const intents = {
  danger: { icon: "warning-sign", intent: Intent.DANGER },
  success: { icon: "tick", intent: Intent.SUCCESS },
  primary: { icon: undefined, intent: Intent.PRIMARY },
  warning: { icon: "hand", intent: Intent.WARNING },
};

function toast(t) {
  if (!t.intent) t.intent = "primary";

  if(typeof(window.lastToast) !== "undefined" && window.lastToast.message === t.message) {
    window.lastToast = t;
    return false;
  }
  window.lastToast = t;

  AppToaster.show({
    action: t.action,
    icon: intents[t.intent].icon,
    message: t.message,
    intent: intents[t.intent].intent,
  });
}

export default toast ;
