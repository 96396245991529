import React, { useState, useContext } from "react";
import Scrollable from "../../../components/scrollable/Scrollable3";
import DirView from "../../../components/dir/DirView";
import {RepoContext} from "../../../components/RepoContext";
import { Collapse, Button} from "@blueprintjs/core";
import BranchSelect from "./BranchSelect";
import Uncommitted from "./Uncommitted";
import "./SidePanel.css";

const mainContainerStyle = {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: 'column' as 'column'
}

const toggleBtnProps = {
    style : {
        //textAlign: "left",
        //width: "100%",
        borderRadius: 0,
        borderRight: 0,
        borderLeft: 0,
        //borderTop: 0,
        textOverflow: "ellipsis",
        textTransform: "uppercase",
    },
    className : "side-panel-toggle-button",
    outlined : true,
    minimal : true,
    alignText : "left",
    fill : true,
} as const;

const collapseProps = {
    transitionDuration : 1000,
    keepChildrenMounted: true
} as const;






export default function () {
  const [dirViewIsOpen, openDirView] = useState(true);
  const [gitStageIsOpen, openGitStage] = useState(true);
  const context = useContext(RepoContext);
  return (
    <div style={mainContainerStyle}>
      <Button 
        {...toggleBtnProps} 
        onClick={()=>openDirView(!dirViewIsOpen)}
        icon={dirViewIsOpen? 'chevron-down' : 'chevron-right'}
        //rightIcon={<AnchorButton {...branchBtnProps} text={`/${context.branch}`} />}
        rightIcon={context.branches.length > 1? <BranchSelect /> : undefined}
        text={context.reponame}
      />
      <Collapse {...collapseProps} isOpen={dirViewIsOpen}>
        <Scrollable className="collapsed-scroll" style={{ height: "100%" }}>
          <DirView className="DirTreeView" />
        </Scrollable>
      </Collapse>
      <Button 
        {...toggleBtnProps} 
        onClick={()=>openGitStage(!gitStageIsOpen)}
        icon={gitStageIsOpen? 'chevron-down' : 'chevron-right'}
        text={"UNCOMMITTED CHANGES"}
      />
      <Collapse {...collapseProps} isOpen={gitStageIsOpen}>
        <Scrollable className="collapsed-scroll" style={{ height: "100%" }}>
          <Uncommitted />
        </Scrollable>
      </Collapse>
    </div>
  );
}
