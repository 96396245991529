const msgColor = {
    error : "darkred",
    warning : "darkgoldenrod",
    info : "darkblue",
}

export default editor => {
    var annotations = editor.getSession().getAnnotations();
    var problemsDiv = document.getElementById("problems-div");
    console.log(annotations);
    problemsDiv.innerHTML = "";
    if(!annotations.length){
        problemsDiv.innerHTML = `
        <div style="margin: 5vw;">
        <h4>No problems detected</h4>
        <p>Any syntax errors or problems with the code will appear here if Portacode supports error detection for the file's programming language.</p>
        </div>`;
    }
    annotations.forEach(annotation => {
        var msg = `${annotation.type.toUpperCase()} at line ${annotation.row+1} char ${annotation.column} <br /> ${annotation.text}`;
        var msgButton = document.createElement("button");
        msgButton.innerHTML = msg;
        msgButton.style = `
        display: block; 
        width: 100%; 
        color: white; 
        border: 0;
        text-align: left;
        margin-bottom: 3px;
        padding: 4px;
        background-color: ${msgColor[annotation.type]}`;
        msgButton.addEventListener("click", (elem, event) => {
            editor.gotoLine(annotation.row+1, annotation.column, true);
        });
        problemsDiv.append(msgButton);
    });
}