import React from "react";
import SimpleBar from 'simplebar-react';
import "./Scrollable3.css";


export default (props: any)=> {
    return (
    <SimpleBar 
    autoHide={true}
    style={props.style} 
    {...props}
    >
        {props.children}
    </SimpleBar>
    );
}

