import React, {useContext, useState} from "react";
import {RepoContext} from "../RepoContext";
import * as idb from "../../db/db-functions"
import "./StatusBar.css";
import "../theme-vars.scss";

export default (props: {darktheme:boolean}) => {
    const context = useContext(RepoContext);
    const [status, setStatus] = useState('LOCAL')
    let theme = props.darktheme? "dark" : "light";
    let currentFile: boolean | string = false;
    if(context.activeTab){
        let file = context.tabs.find((file: any) => file.id === context.activeTab);
        if(file) currentFile = file.path;
        idb.getFileStatus(file.id).then(r => setStatus(r));
    }

    return (
        <p className={`codeeditor-statu-bar ${theme} ${status.toLocaleLowerCase()}`}>
            {currentFile? currentFile : "Open a file"}
        </p>
    );
}