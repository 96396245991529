import { dbPromise as dbop } from "./db-driver.js";
import type { 
  ObjectStoreName,
  //User, 
  //RepoList, 
  //RepoMeta, 
  //RepoTree, 
  //RepoContent, 
  //RepoContentUnsaved, 
  //RepoContentUncommitted 
} from "./db-blueprint";

/**
 * Returns the parent node in a string path
 * 
 * @param {string} path 
 * @param {string} delimiter 
 */
function pop(path: string, delimiter: string = '/'){
  return path.substr(0, path.lastIndexOf(delimiter));
}
export {pop};

/**
 * This function must be called when adding a new source code file 
 *  as it validates the path of that file so that in case the path 
 *  does not exist, it creates the folders of that path.
 * 
 * @param {ObjectStoreName} obj Object store in which the item was created
 * @param {string} id fully qualified id of the item that was just craeted
 * @return {number} number of directories created
 */
async function createParentFolderIfNotExists(obj: ObjectStoreName, id: string){
  const [userid, repoid, branch, path] = id.split('-');
  var parentDir = pop(path);
  if(!parentDir.length) return;
  var dirFQID = `${userid}-${repoid}-${branch}-${parentDir}`;
  // If the parent directory does not exist, call add() on it resulsting in 
  // recursive creation of the path
  if(await get(obj, {id: dirFQID})){
    console.log("Directory ", parentDir, " already exists");
    return;
  }
  console.log("Creating Directory: ", parentDir);
  await add(obj, {
    id: dirFQID,
    userid: userid, 
    repoid: repoid,
    branch: branch,
    path: parentDir,
    type: 'tree',
    dir: pop(parentDir),
    tree: `${userid}-${repoid}-${branch}`
  });
}

/**
 * This function must be called when removing a source code file
 *  as it checks if that was the only/last file in its parent firectory
 *  and it removes all empty directories after removing the file.
 * 
 * @param {ObjectStoreName} obj Object store from which the item was removed
 * @param {string} id fully qualified id of the item that was just removed
 * @return {boolean} number of directories removed
 */
async function removeParentFolderIfBecameEmpty(obj: ObjectStoreName, id: string){
  const [userid, repoid, branch, path] = id.split('-');
  var parentDir = pop(path);
  if(!parentDir.length) return;
  const dirFQID = `${userid}-${repoid}-${branch}-${parentDir}`;
  // if there are no remaining sibling items in this directory,
  // then call remove() on them resulting in recurrsion.
  var remainingBlob = await get(obj, {dir: [parseInt(userid), parseInt(repoid), branch, parentDir]});  
  console.log("Remaingin Blob in ", parentDir, " : ", remainingBlob);
  if(!remainingBlob){
    console.log("Removing empty directory: ", parentDir);
    await remove(obj, dirFQID);
  }
}

/**
 * Inserts an item to an object store.
 *
 * @param {ObjectStoreName} obj Name of the object store.
 * @param {object} itm The item to be inserted.
 * @return {boolean} True on succes, false on failure.
 */
async function add(obj: ObjectStoreName, itm: any) {
  let dbo = await dbop;
  if (!dbo.objectStoreNames.contains(obj)) {
    console.log("Object store '" + obj + "' doesn't exist.");
    return false;
  }

  let p = new Promise((resolve, reject) => {
    var r = dbo.transaction([obj], "readwrite").objectStore(obj).put(itm);
    r.onsuccess = async function (event: any) {
      if(obj === 'repo-content-unsaved' || obj === 'repo-content-uncommitted'){
          await createParentFolderIfNotExists(obj, itm.id);
          resolve(true);
      }
      else{
        resolve(true);
      }
    };

    r.onerror = function (event: any) {
      reject(event.srcElement.error);
    };
  });
  return p;
}
export { add };


/**
 * Remove an item from an object store.
 * 
 * @param obj Name of the object store.
 * @param id id of the item to be removed from the object sotre.
 * @return {boolean} True on succes, false on failure.
 */
async function remove(obj: ObjectStoreName, id: any){
  let dbo = await dbop;
  let p = new Promise((resolve, reject) => {
    var r = dbo.transaction([obj], "readwrite").objectStore(obj).delete(id);
    r.onsuccess = async function (event: any) {
      if(obj === 'repo-content-unsaved' || obj === 'repo-content-uncommitted'){
        await removeParentFolderIfBecameEmpty(obj, id);
        resolve(true);
      }
      else{
        resolve(true);
      }
    };

    r.onerror = function (event: any) {
      reject(event.srcElement.error);
    };
  });
  return p;
}
export {remove}


/**
 * Clear all objects from an object store
 *
 * @param {string} obj Name of the object store.
 * @return {boolean} True on succes, false on failure.
 */
async function clear(obj: ObjectStoreName) {
  let dbo = await dbop;
  if (!dbo.objectStoreNames.contains(obj)) {
    console.log("Object store '" + obj + "' doesn't exist.");
    return false;
  }

  let p = new Promise((resolve, reject) => {
    var r = dbo.transaction([obj], "readwrite").objectStore(obj).clear();
    r.onsuccess = function (event: any) {
      resolve(true);
    };

    r.onerror = function (event: any) {
      reject(event.srcElement.error);
    };
  });
  return p;
}
export { clear };


/**
 * Save a repository's recursive tree to the indexed db if it's 
 *  not already saved, or update it if it does exist and 
 *  the inserted version is newer
 * 
 * @param {number} userid Id of the repo owner 
 * @param {number} repoid Id of the repository
 * @param {string} branch Branch name
 * @param {string} full_name Repo full_name (username/reponame)
 * @param {object} tree The repository tree
 * @param {string} sha The hash of the current version of the tree
 *  if it's the same as that of the last saved version then the call
 *  is aborted and it returns false
 * @param {object} branches Array of available branches of the 
 *  repository that will be saved to the repo-meta object store.
 * 
 * @return {boolean} true on success, false in nothing has happened
 */
async function saveRepo(userid:number, repoid:number, branch:string, full_name:string, tree:any[], sha:string, branches:any[]){
  //let dbo = await dbop;
  const treePath = `${userid}-${repoid}-${branch}`;
  //See if it's already saved in objectStore "repo-local"
  var meta:any = await get("repo-meta", {id: treePath});
  if(!meta){
    // if not, save it
    meta = 
    {
      id: treePath,
      full_name: full_name,
      name: full_name.split('/', 2)[1],
      owner: full_name.split('/', 2)[0],
      userid: userid,
      repoid: repoid,
      branch: branch,
      branches: branches,
      sha: false
    };
    await add("repo-meta", meta);
  }

  // Compare versions from repo-local to see whether it's 
  //  the same data or an update is required...
  if(meta.sha ===  sha) return false;
  
  //await clear(`repo-tree-${treePath}`);

  tree.forEach(item => {
    item.id = `${treePath}-${item.path}`
    item.owner = meta.owner;
    item.reponame = meta.reponame;
    item.branch = branch;
    item.userid = userid;
    item.repoid = repoid;
    let lastSlash = item.path.lastIndexOf("/");
    item.dir = item.path.substring(0,lastSlash);
    item.name = item.path.substring(lastSlash+1);
    add(`repo-tree`, item);
  });

  meta.sha = sha;
  await add( "repo-meta", meta);
  return true;
}
export { saveRepo };


/**
 * Save unsaved changes in a source file. 
 * ie. it move unsaved-blob[x] to uncommitted-blob[x] unless the unsaved object is the same
 *  as the original local object (with a differen uncommitted-blob[x] in between). in such case, 
 *  both uncommitted-blob[x] and unsaved-blob[x] are deleted leaving only the original-blob[x].
 * 
 * @param {string} fileFullyQualifiedId `${userid}-${repoid}-${branch}-${path}`
 * @return {boolean} True on success, false on failure
 */
async function saveFile(fileFullyQualifiedId: string){
  var blob: any = await get('repo-content-unsaved', { id: fileFullyQualifiedId });
  if(!blob) return false;
  var unsaveContent = atob(blob.content);
  var originalBlob:any = await get('repo-content', {id: `${blob.sha}`});
  if(!originalBlob) return false;
  var originalContent = atob(originalBlob.content);
  if(originalContent === unsaveContent){
    // remove both uncommitted-blob[x] and unsaved-blob[x]
    await remove('repo-content-unsaved', fileFullyQualifiedId);
    await remove('repo-content-uncommitted', fileFullyQualifiedId);
    return true;
  }
  else{
    await remove('repo-content-unsaved', fileFullyQualifiedId);
    await add('repo-content-uncommitted', blob);
    return true;
  }
}
export {saveFile};


/**
 * Get a source code file's status (UNSAVED, UNCOMMITTED, LOCAL, or NOTFOUND)
 * 
 * @param {string} fileFullyQualifiedId `${userid}-${repoid}-${branch}-${path}`
 * @return {string} The current status of the file
 */
async function getFileStatus(fileFullyQualifiedId:string){
  let parts = fileFullyQualifiedId.split("-");
  var userid = parts[0];
  var repoid = parts[1];
  var branch = parts[2];
  var path = parts.slice(3).join('-');
  let blob: any = await get('repo-content-unsaved', { id: fileFullyQualifiedId });
  if (blob) return "UNSAVED";
  else blob = await get('repo-content-uncommitted', { id: fileFullyQualifiedId });
  if (blob)  return "UNCOMMITTED";
  else blob = await get('repo-content', { fqi: [`${userid}-${repoid}-${branch}`, path] });
  if (blob) return "LOCAL";
  else return "NOTFOUND";
}
export {getFileStatus};

/**
 * Get a source code file and its status (UNSAVED, UNCOMMITTED, LOCAL, or NOTFOUND)
 * 
 * @param {string} fileFullyQualifiedId `${userid}-${repoid}-${branch}-${path}`
 * @return {object} An object with the following props 
 *  - status: string The current file status
 *  - blob: object the file details and content
 */
async function getFile(fileFullyQualifiedId:string){
  let parts = fileFullyQualifiedId.split("-");
  var userid = parts[0];
  var repoid = parts[1];
  var branch = parts[2];
  var path = parts.slice(3).join('-');
  let blob: any = await get('repo-content-unsaved', { id: fileFullyQualifiedId });
  if (blob) return {status: "UNSAVED", blob: blob};
  else blob = await get('repo-content-uncommitted', { id: fileFullyQualifiedId });
  if (blob)  return {status: "UNCOMMITTED", blob: blob};
  else blob = await get('repo-content', { fqi: [`${userid}-${repoid}-${branch}`, path] });
  if (blob) return {status: "LOCAL", blob: blob};
  else return "NOTFOUND";
}
export {getFile};


/**
 * List content of a repository
 * 
 * @param {number} userid id of repo owner
 * @param {number} repoid id of repository
 * @param {string} branch repo branch
 * @param {string} dir Directory path
 * @ return {Promise<GitItem[]>} array of items on success or
 *  false on failure
 */
async function listDir(userid:number, repoid:number, branch:string, dir:string){
  let dbo = await dbop;
  let objectStore = dbo.transaction("repo-tree").objectStore("repo-tree");
  let p = new Promise(
    (resolve, reject) => {
      var request:any = {};
      request = objectStore.index("dir").openCursor([userid, repoid, branch, dir]);

      let ret:any[] = [];
      request.onsuccess = async (e:any) =>{
        let cursor = e.target.result;
        if(cursor){
          ret.push(cursor.value);
          cursor.continue();
        }
        else{
          for(var i=0, di=0; i<ret.length; i++){
            ret[i].status = await getFileStatus(ret[i].id);
            // Sort directories first
            if(ret[i].type === "tree"){
                if(di<i){
                    ret.splice(di, 0, ret.splice(i, 1)[0]);
                }
                di++;
            }
          }
          resolve(ret);
        }
      }
      request.onerror = (e:any) =>{
        reject(e);
      }
    }
  );
  return p;
}
export {listDir};


/**
 * Retrives an item from an object store.
 *
 * @param {string} obj Name of the object store.
 * @param {object} query Key value pairs of the {index: value} for the
 * specified index.
 * @return {object | undefined | false}  The specified object if found,
 *  undefined if not, and false if the objectStore itself wasn't found.
 */
async function get(obj:ObjectStoreName, query:any) {
  let dbo = await dbop;
  if (!dbo.objectStoreNames.contains(obj)) {
    console.log("Object store '" + obj + "' doesn't exist.");
    return false;
  }

  let os = dbo.transaction([obj], "readwrite").objectStore(obj);

  let key = Object.keys(query)[0];
  var r:any = false;
  if (key === os.keyPath) {
    r = os.get(query[key]);
  } 
  else {
    r = os.index(key).get(query[key]);
  }

  let p = new Promise((resolve, reject) => {
    r.onsuccess = function (event:any) {
      resolve(event.srcElement.result);
    };

    r.onerror = function (event:any) {
      reject(event.srcElement.error);
    };
  });
  return p;
}
export { get };


/**
 * Get all records of an objectStore into an array
 * 
 * @param {string} os ObjectStore name
 * @param {string} index the name of the index used to sort items
 * default null which means the KeyPath.
 * @param key A key or key range to be applied
 * @param {bool}  getCursor Wether to return the cursor rather 
 * than the entire array
 * @return {object} An array of all records in the specified 
 * ObjectStore. Or a cursor object if getCursor is set to true
 */
async function getAll(os:ObjectStoreName, index:string|null = null, key: any = undefined, getCursor:boolean = false) {
  let dbo = await dbop;
  let objectStore = dbo.transaction(os).objectStore(os);
  let p = new Promise(
    (resolve, reject) => {
      var request:any = {};
      if(index)
        request = objectStore.index(index).openCursor(key);
      else
        request = objectStore.openCursor(key);
      let ret:any[] = [];
      request.onsuccess = (e:any) =>{
        let cursor = e.target.result;
        if(getCursor) resolve(cursor);
        else{
          if(cursor){
            ret.push(cursor.value);
            cursor.continue();
          }
          else
            resolve(ret);
        }
      }
      request.onerror = (e:any) =>{
        reject(e);
      }
    }
  );
  return p;
}
export {getAll};
