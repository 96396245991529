import React from "react";
import "./Login.css";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.openChild = this.openChild.bind(this);
    this.checkChild = this.checkChild.bind(this);    
  }
  openChild() {
    this.child = window.open(
      "https://old.portacode.com/?userCommand=login",
      "_blank",
      "width=626,height=436"
    );
    this.timer = setInterval(this.checkChild, 200);
  }
  checkChild() {
    if (this.child.closed) {
      window.location.reload();
      clearInterval(this.timer);
    }
  }
  handleInstallPrompt() {
    const addBtn = document.querySelector(".install-button");
    addBtn.style.display = "none";

    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      let deferredPrompt = e;
      // Update UI to notify the user they can add to home screen
      addBtn.style.display = "block";

      addBtn.addEventListener("click", (e) => {
        // hide our user interface that shows our A2HS button
        addBtn.style.display = "none";
        // Show the prompt
        deferredPrompt.prompt();
        // Wait for the user to respond to the prompt
        deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === "accepted") {
            console.log("User accepted the A2HS prompt");
            //alert("Installing app");
          } else {
            console.log("User dismissed the A2HS prompt");
            //alert("Operation dismissed");
          }
          deferredPrompt = null;
        });
      });
    });
  }
  componentDidMount() {
    this.handleInstallPrompt();
  }
  render() {
    return (
      <div className="login-body">
        <div className="login-box">
          <img alt="Portacode" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMAAAADABAMAAACg8nE0AAAAD1BMVEVldC0dISNRYWuQmJ7c5Ol+6K6MAAAAAXRSTlMAQObYZgAABMdJREFUeNq1nGGO3CAMhSdSDxBGXGBWe4D2Bs2K+59pE0gICTbYwS8/KlXa4c2LvwFi47xemmty6zW/UFccHiiRxwcpFONjFNzlQo9vrzDdBWawAWsLUy0wgw3YWviz1OP7/4YCPtQCYbGMQKgsrJqzYQRqC5ukJUJ3C1FxNkTIh4uHkAQtEQqlh13OCKR0/0Mc8/319cn/MQJpRyhsFtbxNwWfBIxA2gMaB/2KVxKzAikjtI76sw6+inxnAxYg5Ziu/2zjbwrRgBFIBUKrgS3AayC+gx1IBUI+GdgsLGYgXRH6JIG3IUhXhI7fmR1IV4Q+h8DbDKQaocOCDUgEQqcFC5AohCxBIhGyBIlGyA4kBiE7kDiErEBiEbICiUfIBqQGQjYgtRCyAKmJkAVISxMhAiSvRCg0ESJACjqQ8u1hEKpAWm+TLgJHhBmELiDtcZ51P+LtYw2EbiDF76NDKH6ER+gGUnQht5Aj3ECoBOmIs9hAyJC2DRwg7aAGqYUiwr5tIIF0xlkcgRzhd9tAAumM8yxF6Ijw0jOwWfBFnMUP9XvofnoGdpBynGcpQinCoW8ggZRXtqBAaP1WAgPRQo6zBKRiGXASA+mvghik6VwGlrfEQATpXNm6UXDFSiYzkKKQVzYnRMiJECpAcjKQQrEZkhqIFhYZSAVCTmwgWnAykPQI6UDiENoUb9czkDiECIGbhAwkFiFSIOhBYhGiBYIWJB4hRiAoQeIR4gQWFUiNWYgTCCqQGrMQK7AoQGrNQqxAUIDUmoV4gUUMUnMWkgm0QWrOQrxAkM5I7YXM30fzjAUepPZCVgmcCotsRuosZLWAU85InYWMEPDcpEqC1FvICAFHCnAg9RYySsBTQWBA6u6F5AI0SN29ECXgSAESpP5eSCFAgeS6eyGFwBWkqwC/F9IIlCClezTlMdi9kEagBClZmPLfs9tplcAJ0i5QDMJt5lQCJ0j7PTo/wO5GdQI7SIfAdI7C7kZ1AjtI6fpbVIr57bRSIIKUq87TJfwmAuVIhUDjiUw+F1VDzS+BAbVAOdZjAfpBobbwEtwhvYAjBZxGwKMFQisEBgIBLMA9DLYF+kHuPQo2BdwjgcWJMZX8DuQGih+aZqp4YEA32T0wMKuma7EBcroWLDhiA6f0rFoyxQbKJVOz6IvGrxZ9xbZFNH61bVFsvAT3n9h4KbaO/a9PbR1f/55tfiUJwnlk+65IcT57AFEkaR89QqnSzE8eAlX5zSePsboMrf5BXJlj1qcStFlybTJEnedXpnMeVCpECamRFLkopTaU5JckBYfKFJK05lihRZCYHSsVCVLLg8WufnJ8sMrST++P1om6BYrRSle3xDJcq+sViYarjb0y13i9lAHJK/ZC7YovvNQIL5biy73wgjW+5A4/NIA/9gA/uIE/egI/PIM//gM/wIQ/ggU/RIY/Bgc/yIc/igg/TIk/Dgo/0Io/kgs/VIw/Fg0/2I0/mg4/XI9vD4A3OOBbNOBNJvg2GXijD75VCd5shW8Xgze84Vv24E2HNUhv47ZJeOMnvnX10nwbDRg33+Lbh+EN0PgWbngTOr6NHv4iAPyrDOAvY8C/TgL+Qgz8Kz3gLyXBv1YF/2IY+Ktt8C/nOWrbuuF/AanvBqzI/nQaAAAAAElFTkSuQmCC" />
          <hr />
          <p>
            Sign in to <b>Portacode</b>
            <br />
            with your <b>Github</b> account
            <br />
            for a seamless access to your repositories
          </p>
          <hr />
          <p>
            Please read our
            <br /> <a href="https://old.portacode.com/terms">
              Terms of Service
            </a>{" "}
            and <br />
            <a href="https://old.portacode.com/privacy">Privacy Policy</a>
          </p>
          <hr />
          <div>
            <button onClick={this.openChild}>
              <span className="github-icon"></span> Sign in
            </button>
          </div>
          <div className="install-button" style={{display: "block"}}>
            <button>Add app to Desktop</button>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
