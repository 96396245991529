import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import SessionLoader from "./components/SessionLoader";
import * as serviceWorker from "./serviceWorker";
//import Temp from "./Temp.js";
import App from "./App.js";
//import Nav from "./components/Nav.js";
import { PageContextProvider } from "./components/PageContext";
//import Loading from "./pages/Loading";
//import {AppToaster} from "./components/AppToaster.js";
//import {TreeExample} from "./components/DirView.js";
//import Loading from "./pages/Loading.js";

ReactDOM.render(
  <PageContextProvider>
    <SessionLoader>
      <App />
    </SessionLoader>
  </PageContextProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
