import React from "react";
import { Repos } from "./repo/Repos";
import { Menu, MenuItem, MenuDivider } from "@blueprintjs/core";
import { PageContext } from "./PageContext";
class MainMenu extends React.Component {
  static contextType = PageContext;
  render() {
    return (
      <Menu>
        <MenuDivider
          title={
            <div>
              <p>Signed in as</p>
              <p>
                <b>{this.context.user.handle}</b>
              </p>
            </div>
          }
        />
        <MenuDivider title="View" />
        <Repos />
        <MenuItem icon="git-push" text="Local Changes" label="Ctrl+P" />
        <MenuItem icon="git-commit" text="Commits" label="Ctrl+H" />
        <MenuDivider />
        <MenuItem
          icon={this.context.darktheme ? "flash" : "moon"}
          shouldDismissPopover={false}
          text={this.context.darktheme ? "Light Theme" : "Dark Theme"}
          onClick={this.context.toggleTheme}
        />
        <MenuItem icon="cog" text="Settings..." />
      </Menu>
    );
  }
}

export { MainMenu };
