import React from "react";
import "./App.css";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import Dashboard from "./pages/Dashboard.js";
import Container from "./components/Container";
import Loading from "./pages/Loading.js";
import Nav from "./components/Nav";
import RepoEditorLoader from "./pages/RepoEditor";
// import ReactGA from 'react-ga';
// ReactGA.initialize('G-YJQZNKLPY2');
import { gtag, install } from 'ga-gtag';
install('G-YJQZNKLPY2') 

console.log("PORTACODE V3.4.3")

window.onhashchange = function() {
  console.log("The URL has changed to: " + window.location.hash);
  //ReactGA.pageview(window.location.pathname + window.location.search + window.location.hash);
};

class App extends React.Component {
  render() {
    return (
      <Container fillHeight={true} noMargin={true}>
        <Nav />
      <Router>
        <Switch>
          <Route exact path="/">
            <Dashboard />
          </Route>
          <Route path="/test" name="dir">
            <Loading debug={true} />
          </Route>
          <Route path="/terms" name="Terms">
            <h1>Hello terms</h1>
          </Route>
          <Route name="Portacode Editor" path="/repo/:ownername/:reponame/:branch/:path" component={RepoEditorLoader} />
          <Route name="Portacode Editor" path="/repo/:ownername/:reponame/:branch"  component={RepoEditorLoader} />
          <Route>
            <h1>This is the default page</h1>
          </Route>
        </Switch>
      </Router>
      </Container>
    );
  }
}

export default App;
