import React from "react";
//import idb from "../db/db.js";
//import * as api from "../components/api-module.js";
//import { PageContext } from "../components/PageContext.js";
//import Loading from "../pages/Loading.js";
import Summary from "./Dashboard/Summary";
import Welcome from "./Dashboard/Welcome";



class Dashboard extends React.Component {
  //static contextType = PageContext;
  render() {
    var nodata = true;
    if(nodata){
      return (
        <Welcome />
      )
    }
    return (
      <Summary />
    );
  }
}

export default Dashboard;
