/**
 * @file Reflection API Auto generated JS module.
 * @see https://github.com/meena-erian/reflection-api
 * @author Menas Erian
 * @copyright (C) 2020 Menas Erian
 */


var call = {
  timeout: -1,
  stack: [],
  send: function () {
    if (call.stack.length) {
      let s = call.stack;
      call.stack = [];
      var xhttp = new XMLHttpRequest();
      xhttp.open("POST", "https://api.portacode.com/processes?type=api");
      xhttp.withCredentials = true;
      xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
      xhttp.onload = function (e) {
        call.resolve(s, JSON.parse(e.target.response));
      };
      xhttp.onerror = function () {
        call.reject(s);
      };
      xhttp.send(JSON.stringify(s));
      console.log(s);
    }
  },
  resolve: function (callStack, serverResponse) {
    serverResponse.forEach((element, i) => {
      callStack[i].promise.resolve(element);
    });
    console.log(
      "Calles resolved:\n--------------\n",
      "Request:\n",
      callStack,
      "\n--------------\n",
      "Response\n",
      serverResponse
    );
  },
  reject: function (callStack) {
    callStack.forEach((c) => {
      c.promise.reject("Connection failed");
    });
  },
};
/**
 * Get information of the currently loggedin user.
 * @return object A gitUser object of the current user.
 */
function getuser() {
  let params = Array.prototype.slice.call(arguments);
  var p = new Promise(function (resolve, reject) {
    call.stack.push({
      name: "getuser",
      params: params,
      promise: {
        resolve: resolve,
        reject: reject,
      },
    });
  });
  if (call.timeout !== -1) clearTimeout(call.timeout);
  call.timeout = setTimeout(call.send, 700);
  return p;
}

export {getuser};

/**
 * Used to let the client know when a new version of the PWA
 * is released so it can automatically update the service worker.
 * @return number Version number.
 */
function get_version() {
  let params = Array.prototype.slice.call(arguments);
  var p = new Promise(function (resolve, reject) {
    call.stack.push({
      name: "get_version",
      params: params,
      promise: {
        resolve: resolve,
        reject: reject,
      },
    });
  });
  if (call.timeout !== -1) clearTimeout(call.timeout);
  call.timeout = setTimeout(call.send, 700);
  return p;
}

export {get_version};

/**
 * Get a list of repositories of the current user.
 * @return  An array of repositories of
 * the currently authenticated user or null if
 * if there's no authenticated user.
 */
function repos() {
  let params = Array.prototype.slice.call(arguments);
  var p = new Promise(function (resolve, reject) {
    call.stack.push({
      name: "repos",
      params: params,
      promise: {
        resolve: resolve,
        reject: reject,
      },
    });
  });
  if (call.timeout !== -1) clearTimeout(call.timeout);
  call.timeout = setTimeout(call.send, 700);
  return p;
}

export {repos};

/**
 * Get a list of all branches of a repository
 * @param string repoFullName OwnerName/RepositoryName
 * @return object List of branches
 */
function branchs(repoFullName) {
  let params = Array.prototype.slice.call(arguments);
  var p = new Promise(function (resolve, reject) {
    call.stack.push({
      name: "branchs",
      params: params,
      promise: {
        resolve: resolve,
        reject: reject,
      },
    });
  });
  if (call.timeout !== -1) clearTimeout(call.timeout);
  call.timeout = setTimeout(call.send, 700);
  return p;
}

export {branchs};

/**
 * Get a full map of the content of the code of a repository
 * @param string repo The repository name
 * @param string branch (optional) The branch name
 * @return object Containing the keys she: the tree's sha and
 * tree: A recursive list of items in the specified repo
 */
function tree(repo, branch) {
  let params = Array.prototype.slice.call(arguments);
  var p = new Promise(function (resolve, reject) {
    call.stack.push({
      name: "tree",
      params: params,
      promise: {
        resolve: resolve,
        reject: reject,
      },
    });
  });
  if (call.timeout !== -1) clearTimeout(call.timeout);
  call.timeout = setTimeout(call.send, 700);
  return p;
}

export {tree};

/**
 * Get file content of a git object by its sha.
 * Note that this API is limited to files of this 2MB or less
 * @param string user Username or organization name of the owner
 * of the repository.
 * @param string repo Repository name
 * @param string sha The hash code of a particular version of
 * a particular file in a repository.
 * @return string The content of the file encoded in basae_64.
 */
function blob(user, repo, sha) {
  let params = Array.prototype.slice.call(arguments);
  var p = new Promise(function (resolve, reject) {
    call.stack.push({
      name: "blob",
      params: params,
      promise: {
        resolve: resolve,
        reject: reject,
      },
    });
  });
  if (call.timeout !== -1) clearTimeout(call.timeout);
  call.timeout = setTimeout(call.send, 700);
  return p;
}

export {blob};

/**
 * Returns an array of the recursive tree of content of the latest version
 * of a repository and a list of available branches.
 * @param string repoFullName OwnerName/RepositoryName
 * @param string branchName 
 * @return  
 */
function openRepo(repoFullName, branchName) {
  let params = Array.prototype.slice.call(arguments);
  var p = new Promise(function (resolve, reject) {
    call.stack.push({
      name: "openRepo",
      params: params,
      promise: {
        resolve: resolve,
        reject: reject,
      },
    });
  });
  if (call.timeout !== -1) clearTimeout(call.timeout);
  call.timeout = setTimeout(call.send, 700);
  return p;
}

export {openRepo};
