import React from "react";
import { PageContext } from "../PageContext";
import "./Resizable.css";
import { Button } from "@blueprintjs/core";


class Resizable extends React.Component<{ style?: object, vertical: boolean }>{
    static defaultProps: any = {
        vertical: false
    }
    render() {
        var gridTemplate = "";
        if (this.props.children) {
            React.Children.toArray(this.props.children).forEach((c: any) => {
                if (typeof (c) === "object" && c.props) {
                    if (c.props.resizable || c.props.togglable)
                        gridTemplate += "auto ";
                    else
                        gridTemplate += "1fr ";
                }
            });
        }
        var gridStyle: any = {};
        gridStyle[`gridTemplate${this.props.vertical ? "Rows" : "Columns"}`] = gridTemplate;
        return (
            <div style={{
                overflow: "hidden",
                width: "100%",
                height: "100%",
                display: "grid",
                ...gridStyle,
                ...this.props.style
            }}
            //onMouseMove={this.broadCastMouseMoveEvent}
            >
                {this.props.children}
            </div>
        );
    }
}

class ResizableItem extends React.PureComponent<{
    color: number,
    resizable: boolean,
    sensorLocation: "right" | "left" | "top" | "bottom" | undefined,
    initialSize?: number,
    minSize?: number,
    maxSize?: number,
    togglable?: boolean,
    collapsed?: boolean,
    style?: object
}>{
    static contextType = PageContext;
    static defaultProps: any = {
        color: 1,
        resizable: false,
        togglable: false,
        sensorLocation: undefined,
        initialSize: "100%",
        collapsed: false
    }
    public state: any = {
        size: this.props.initialSize,
        resizing: false,
        collapsed: this.props.collapsed,
        lastPoint: false,
        lastSize: this.props.initialSize,
        lastTime: 0,
    }
    constructor(props: any) {
        super(props);
        this.handleMouseResizing = this.handleMouseResizing.bind(this);
        this.toggle = this.toggle.bind(this);
    }
    public varDimention : string =
    (
        this.props.sensorLocation === "right" ||
        this.props.sensorLocation === "left"
    ) ? "width" : "height";

    public itemDiv: any = null;
    private handleMouseResizing(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        if (!this.state.resizing) return;
        //let lasttUpdate = this.state.lastTime;
        let currentTime = (new Date()).getTime();
        //if(currentTime - lasttUpdate < 100) return;
        let from = this.state.lastPoint;
        e.preventDefault();
        var change = 0;
        switch (this.props.sensorLocation) {
            case "right":
                change = e.clientX - from.x ;
                break;
            case "left":
                change =  from.x - e.clientX;
                break;
            case "bottom":
                change = e.clientY - from.y;
                break;
            case "top":
                change = from.y - e.clientY;
                break;
        }
        let newSize = this.state.lastSize + change;
        if (this.props.minSize && newSize < this.props.minSize) newSize = this.props.minSize;
        else if (this.props.maxSize && newSize > this.props.maxSize) newSize = this.props.maxSize;
        this.setState({ size: newSize, lastTime: currentTime });
    }
    private toggle() {
        this.setState({ collapsed: !this.state.collapsed });
    }
    render() {
        var icon: any = false;
        switch (this.props.sensorLocation) {
            case "right": icon = "double-chevron-left"; break;
            case "left": icon = "double-chevron-right"; break;
            case "top": icon = "double-chevron-down"; break;
            case "bottom": icon = "double-chevron-up"; break;
        }
        var styleVar: any = {};
        styleVar[this.varDimention] = this.state.collapsed ? 0 : this.state.size
        if(!this.state.resizing) styleVar['transition'] = "all 0.3s ease";
        return (
            <div
                ref={(div) => this.itemDiv = div}
                className={
                    `${this.context.darktheme ? "bp3-dark dark" : "light"}-${this.props.color}` +
                    ` resizable-${this.varDimention}`
                }
                style={{
                    ...this.props.style,
                    ...styleVar,
                }}
            >
                <div style={{
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                    //display: "flex",
                }}>
                    {this.props.children}
                </div>
                
                
                {
                    (
                        this.state.collapsed ||
                        (!this.props.resizable) ||
                        (this.props.sensorLocation === undefined)
                    )
                        ? "" :
                        <div
                            className={`resizing-sensor${this.state.resizing ? " active " : " "}${this.props.sensorLocation}`}
                            style={{
                                overflow: "overlay",
                                position: "absolute",
                            }}
                            onMouseDown={(e) => { 
                                e.preventDefault(); 
                                this.setState({ 
                                    resizing: true, 
                                    lastPoint: {x: e.clientX, y: e.clientY},
                                    lastSize: this.state.size,
                                    lastTime: (new Date()).getTime()
                                }); 
                            }}
                            onMouseMove={this.handleMouseResizing}
                            onMouseUp={(e) => { e.preventDefault(); this.setState({ resizing: false }); }}
                            onMouseLeave={(e) => { e.preventDefault(); this.setState({ resizing: false }); }}
                        />
                }
                {!this.props.togglable ? "" :
                    <Button
                        className={`bp3-small resizable-toggle` +
                            ` ${this.props.sensorLocation}` +
                            ` ${this.state.collapsed ? " collapsed" : ""}`
                        }
                        icon={icon}
                        onClick={this.toggle}
                    />
                }
            </div>
        );
    }
}

export { Resizable, ResizableItem }