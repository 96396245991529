import React from "react";
import Container from "../../components/Container";

export default class Summary extends React.Component{
    render(){
        return (
            <Container noMargin={true} style={{height: "calc( 100vh - 50px )", display: "grid"}}>
            <Container color={2}>
              <h1>Pending changes</h1>
              <p>
                Changes saved on your local device waiting for you to push them to
                the repository.
              </p>
            </Container>
            <Container color={1}>
              <h1>Pinned repositories</h1>
              <p>
                When you open a repository in portacode, it's automatically pinned
                and some of its data is saved on your local device for offline use
                and quicker loading speed the next time you open it
              </p>
            </Container>
          </Container>
        );
    }
}