import React, { useState } from "react";

/**
 * The RepoContextProvider component is meant to broadcast Repository-related data 
 *  and metods to all children tree. Any component in the tree can access this data
 *  by importing the RepoContext component.
 * 
 * Note: Some of these data is supposed to be provided to the RepoContextProvider
 *  in the props in order to identify the repository.
 * 
 * Note: The RepoContextProvider is NOT responsible for loading repo info from the
 *  server side or from the indexedDB. it only broadcasts the data. 
 * Refer to the RepoEditorLoader to see how repo data is loaded.
 */

const RepoContext: React.Context<any> = React.createContext(false);

interface File {
    dir?: string;
    icon?: string; //Example: "document"
    id?: string;   //"12345-72294785-master-StringFormating.cpp"
    isSelected?: boolean;
    label?: string; //Example: "StringFormating.cpp"
    mode?: string; //Example: "100644"
    path?: string; //Example: "StringFormating.cpp"
    sha?: string; //Example: "8acabe3dca330294a89bcf41a6a81ea4e3934aa1"
    size?: number; //Example: 1171
    type?: string; //Example: "blob"
    ace?:any;
}

interface RepoContextProviderProps {
    userid: number;
    repoid: number;
    branch: string;
    branches: any[];
    default_branch: string;
    owner: string;
    reponame: string;
    full_name: string;
    sha: string;
    key?: string;
    children?: any;
}

const RepoContextProvider = (props: RepoContextProviderProps) => {
    const [activeTab, setActiveTab] = useState(undefined);
    const [tabs, setTabs] = useState<File[]>([]);

    function activateTab(id: any) {
        setActiveTab(id);
    }

    function closeTab(id: any, evnt: any) {
        if(evnt) evnt.stopPropagation();
        console.log("Closing tab: ", id);
        let target: undefined | File = tabs.find((file: File) => file.id === id);
        console.log("Closing file: ", target);
        console.log("Currently active tab: ", activeTab);
        if (target) {
            let newTabs: any = tabs.filter((file: any) => file.id !== id);
            if (target.id === activeTab) {
                if (tabs.length > 1) {
                    activateTab(newTabs[newTabs.length - 1].id);
                }
                else {
                    activateTab(undefined);
                }
            }
            setTabs(newTabs);
        }
        else {
            console.warn("Tab already closed");
        }
    }

    function openFile(n: { id: string }, p: any, e: any) {
        activateTab(n.id);
        if (tabs.find((file: File) => file.id === n.id)) {
            // Activate this tab
        }
        else {
            // Open tab
            var newTabs: any = tabs;
            newTabs.push(n);
            setTabs(newTabs);
        }
    }

    function registerAce(id :string, ace: any){
        var newTabs: any = tabs;
        var index = newTabs.findIndex((file: File) => file.id === id);
        newTabs[index].ace = ace;
        setTabs(newTabs);
    }

    return (
        <RepoContext.Provider
            value={{
                ...props,
                activeTab,
                tabs,
                activateTab,
                closeTab,
                openFile,
                registerAce
            }}>
            {props.children}
        </RepoContext.Provider>
    );
}

export { RepoContextProvider, RepoContext };