import * as React from "react";
import { RepoContext } from "../../../components/RepoContext";
import { MenuItem, AnchorButton } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import { branchSelectProps, Branch } from "./Branch";

const BranchSelect = Select.ofType<Branch>();

export default class Branches extends React.Component {
  static contextType = RepoContext;

  public items =
    typeof this.context.branches !== "undefined"
      ? this.context.branches.map((b: any, index:number) => {
          return { 
            name: b.name,
            id: index,
            default: b.name === this.context.default_branch,
            current: b.name === this.context.branch
          };
        })
      : [];

  public componentDidMount(){
    console.log(this.context);
  }
  public render() {
    return (
      <div 
        style={{display: "contents"}}
        onClick={(e)=>e.stopPropagation()}
      >
        <BranchSelect
          {...branchSelectProps}
          popoverProps={{
            minimal: true,
            usePortal: true,
            captureDismiss: true,
            popoverClassName: "branch-list",
            //targetClassName: "branch-list-target",
            //portalClassName: "branch-list-portal",
            position: "bottom-right",
            wrapperTagName: "div",
          }}
          //className="branch-list-main"
          items={this.items}
          noResults={<MenuItem disabled={true} text="No such branch." />}
          onItemSelect={this.handleValueChange}
        >
          <AnchorButton
            className="bp3-minimal"
            icon="git-branch"
            text={this.context.branch}
            //shouldDismissPopover={false}
          />
        </BranchSelect>
      </div>
    );
  }

  private handleValueChange = (branch: Branch) => {
    window.location.href = `#/repo/${this.context.full_name}/${branch.name}`;
  };
}
