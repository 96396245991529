import React from "react";
import { RepoContext } from "../RepoContext";
import * as api from "../api-module";
import idb from "../../db/db.js";
import Loading from "../../pages/Loading";
import ace from "./ace";
import toast from "../AppToaster.js";
import "./CodeEditor.css";
import printAnnotations from "./CodeEditor/printAnnotations";


const darkTheme = "ace/theme/twilight";
const lightTheme = "ace/theme/textmate";


interface CodeEditorDivProps {
    darktheme: boolean;
    file: any;
}

export default class CodeEditorDiv extends React.PureComponent<CodeEditorDivProps>{
    static contextType = RepoContext;
    public editorDiv: any = false;
    public editorObj: any = false;
    public interval: any = false;
    content = "";
    public state: any = {
        contentStatus: undefined,
        loading: true,
        active: false,
        options: {
            enableLiveAutocompletion: 1,
            fontSize: 16,

            // editor options
            selectionStyle: 'text',// "line"|"text"
            highlightActiveLine: true, // boolean
            highlightSelectedWord: true, // boolean
            readOnly: false, // boolean: true if read only
            cursorStyle: 'ace', // "ace"|"slim"|"smooth"|"wide"
            mergeUndoDeltas: true, // false|true|"always"
            behavioursEnabled: true, // boolean: true if enable custom behaviours
            wrapBehavioursEnabled: true, // boolean
            autoScrollEditorIntoView: true, // boolean: this is needed if editor is inside scrollable page
            keyboardHandler: null, // null, "ace/keyboard/vim", "ace/keyboard/emacs", "ace/keyboard/sublime" function: handle custom keyboard events

            // renderer options
            animatedScroll: false, // boolean: true if scroll should be animated
            displayIndentGuides: true, // boolean: true if the indent should be shown. See 'showInvisibles'
            showInvisibles: false, // boolean -> displayIndentGuides: true if show the invisible tabs/spaces in indents
            showPrintMargin: true, // boolean: true if show the vertical print margin
            printMarginColumn: 80, // number: number of columns for vertical print margin
            printMargin: undefined, // boolean | number: showPrintMargin | printMarginColumn
            showGutter: true, // boolean: true if show line gutter
            fadeFoldWidgets: false, // boolean: true if the fold lines should be faded
            showFoldWidgets: true, // boolean: true if the fold lines should be shown ?
            showLineNumbers: true,
            highlightGutterLine: true, // boolean: true if the gutter line should be highlighted
            hScrollBarAlwaysVisible: false, // boolean: true if the horizontal scroll bar should be shown regardless
            vScrollBarAlwaysVisible: false, // boolean: true if the vertical scroll bar should be shown regardless
            fontFamily: undefined, // string: set the font-family css value
            //maxLines: 10000, // number: set the maximum lines possible. This will make the editor height changes
            //minLines: 30, // number: set the minimum lines possible. This will make the editor height changes
            maxPixelHeight: 0, // number -> maxLines: set the maximum height in pixel, when 'maxLines' is defined. 
            scrollPastEnd: 0.5, // number -> !maxLines: if positive, user can scroll pass the last line and go n * editorHeight more distance 
            fixedWidthGutter: true, // boolean: true if the gutter should be fixed width
            //theme: 'ace/theme/twilight', // theme string from ace/theme or custom?

            // mouseHandler options
            scrollSpeed: 2, // number: the scroll speed index
            dragDelay: 0, // number: the drag delay before drag starts. it's 150ms for mac by default 
            dragEnabled: true, // boolean: enable dragging
            //focusTimout: 0, // number: the focus delay before focus starts.
            tooltipFollowsMouse: false, // boolean: true if the gutter tooltip should follow mouse

            // session options
            //firstLineNumber: 1, // number: the line number in first line
            //overwrite: false, // boolean
            //newLineMode: 'auto', // "auto" | "unix" | "windows"
            useWorker: true, // boolean: true if use web worker for loading scripts
            useSoftTabs: true, // boolean: true if we want to use spaces than tabs
            tabSize: 4, // number
            wrap: false, // boolean | string | number: true/'free' means wrap instead of horizontal scroll, false/'off' means horizontal scroll instead of wrap, and number means number of column before wrap. -1 means wrap at print margin
            indentedSoftWrap: true, // boolean
            foldStyle: 'markbegin', // enum: 'manual'/'markbegin'/'markbeginend'.
            //mode: 'ace/mode/text' // string: path to language mode 
        }
    }
    constructor(props: CodeEditorDivProps) {
        super(props);
        this.requestContent = this.requestContent.bind(this);
        this.loadBlob = this.loadBlob.bind(this);
        this.syncTheme = this.syncTheme.bind(this);
        this.syncOptions = this.syncOptions.bind(this);
        this.syncSettings = this.syncSettings.bind(this);
        this.proccessContentChanges = this.proccessContentChanges.bind(this);
    }
    async requestContent(firstLoad = false) {
        const repoContext = this.context;
        let content = await api.blob(repoContext.owner, repoContext.reponame, this.props.file.sha);
        if (typeof content === 'string') {
            idb.add('repo-content', {
                id: this.props.file.sha,
                tree: `${repoContext.userid}-${repoContext.repoid}-${repoContext.branch}`,
                path: this.props.file.path,
                content: content
            });
        }
        else {
            console.error("Failed to load blob");
        }
        if (firstLoad) {
            this.editorObj.setValue(atob(content), 1);
            this.setState({ loading: false });
        }
    }
    async loadBlob() {
        const repoContext = this.context;
        const itemFullyQualifiedID = `${repoContext.userid}-${repoContext.repoid}-${repoContext.branch}-${this.props.file.path}`;
        var loadedFrom: any = undefined;
        let blob: any = await idb.get('repo-content-unsaved', { id: itemFullyQualifiedID });
        if (blob) loadedFrom = "UNSAVED";
        else blob = await idb.get('repo-content-uncommitted', { id: itemFullyQualifiedID });
        if(!loadedFrom) {
            if (blob)  loadedFrom = "UNCOMMITTED";
            else blob = await idb.get('repo-content', { id: this.props.file.sha });
        }
        if (blob)  {
            if(!loadedFrom) loadedFrom = "LOCAL";
            this.editorObj.setValue(atob(blob.content), 1);
            this.setState({ loading: false });
            this.requestContent();
        }
        else {
            toast({
                message: `Loading file: ${this.props.file.path}`,
            });
            this.requestContent(true);
        }
        this.setState({ contentStatus: loadedFrom });
    }
    async proccessContentChanges() {
        if(this.state.loading) return false;
        //console.log("proccessContentChanges");
        let currentContent = this.editorObj.getValue();
        const repoContext = this.context;
        const itemFullyQualifiedID = `${repoContext.userid}-${repoContext.repoid}-${repoContext.branch}-${this.props.file.path}`;
        var loadedFrom: any = undefined;
        let blob: any = await idb.get('repo-content-unsaved', { id: itemFullyQualifiedID });
        if (blob) loadedFrom = "UNSAVED";
        else blob = await idb.get('repo-content-uncommitted', { id: itemFullyQualifiedID });
        if(!loadedFrom) if (blob)  loadedFrom = "UNCOMMITTED";
        else blob = await idb.get('repo-content', { id: this.props.file.sha });
        if(!loadedFrom) if (blob)  {
            loadedFrom = "LOCAL";
        }
        else {
            toast({
                message: `The file: ${this.props.file.path} was recently modified in another session!`,
                intent: "warning"
            });
            this.requestContent(true);
        }
        if(blob){
            let savedContent = atob(blob.content);
            if(savedContent !== currentContent){
                //console.log("savedContent:", savedContent);
                //console.log("currentContent:", currentContent);
                switch(loadedFrom){
                    case "LOCAL":
                        // Create unsaved blob for this object
                        //console.log("Saving new content:", currentContent);
                        await idb.add('repo-content-unsaved', {
                            id: itemFullyQualifiedID,
                            userid: repoContext.userid,
                            repoid: repoContext.repoid,
                            branch: repoContext.branch,
                            dir: idb.pop(this.props.file.path),
                            type: 'blob',
                            tree: `${repoContext.userid}-${repoContext.repoid}-${repoContext.branch}`,
                            path: this.props.file.path,
                            sha: this.props.file.sha,
                            content: btoa(currentContent)
                        });
                        loadedFrom = "UNSAVED";
                    break;
                    case "UNSAVED":
                        var subLoadedFrom: any = undefined;
                        var blobBeforeChange: any = await idb.get('repo-content-uncommitted', { id: itemFullyQualifiedID });
                        if(blobBeforeChange){
                            subLoadedFrom = "UNCOMMITTED";
                        }
                        else blobBeforeChange =  await idb.get('repo-content', { id: this.props.file.sha });
                        if(!subLoadedFrom && blobBeforeChange) subLoadedFrom = "LOCAL";
                        if(atob(blobBeforeChange.content) === currentContent){
                            //Remove this unsaved blob from the indexdb.
                            //console.log("Removing unsaved blob object");
                            await idb.remove('repo-content-unsaved', itemFullyQualifiedID);
                            loadedFrom = subLoadedFrom;
                        }
                        else {
                            // update unsaved blob object.
                            //console.log("update unsaved blob object");
                            await idb.add('repo-content-unsaved', {
                                id: itemFullyQualifiedID,
                                userid: repoContext.userid,
                                repoid: repoContext.repoid,
                                branch: repoContext.branch,
                                dir: idb.pop(this.props.file.path),
                                type: 'blob',
                                tree: `${repoContext.userid}-${repoContext.repoid}-${repoContext.branch}`,
                                path: this.props.file.path,
                                sha: this.props.file.sha,
                                content: btoa(currentContent)
                            });
                        }
                    break;
                    case "UNCOMMITTED":
                        //console.log("Create unsaved blob object");
                            await idb.add('repo-content-unsaved', {
                                id: itemFullyQualifiedID,
                                userid: repoContext.userid,
                                repoid: repoContext.repoid,
                                branch: repoContext.branch,
                                dir: idb.pop(this.props.file.path),
                                type: 'blob',
                                tree: `${repoContext.userid}-${repoContext.repoid}-${repoContext.branch}`,
                                path: this.props.file.path,
                                sha: this.props.file.sha,
                                content: btoa(currentContent)
                            });
                        loadedFrom = "UNSAVED";
                    break;
                }
                this.setState({contentStatus: loadedFrom});
            }
        }
    }
    syncTheme() {
        let currentTheme = this.editorObj.getTheme();
        let referenceTheme = this.props.darktheme ? darkTheme : lightTheme;
        if (currentTheme !== referenceTheme) this.editorObj.setTheme(referenceTheme);
    }
    syncOptions() {
        Object.keys(this.state.options).forEach(key => {
            if (this.state.options[key] !== this.editorObj.getOption(key)) {
                this.editorObj.setOption(key, this.state.options[key]);
            }
        });
    }
    syncSettings() {
        this.syncTheme();
        this.syncOptions();
        this.editorObj.renderer.$updateCachedSize();
    }
    componentDidUpdate() {
        let isActive = this.props.file.id === this.context.activeTab;
        if (isActive && !this.state.active) {
            //console.log("Activating tab: ", this.props.file.id);
            printAnnotations(this.editorObj);
            this.setState({ active: true });
        }
        else if (!isActive && this.state.active) {
            //console.log("deactivating tab: ", this.props.file.id);
            this.setState({ active: false })
        }
    }
    componentDidMount() {
        const repoContext = this.context;
        const itemFullyQualifiedID = `${repoContext.userid}-${repoContext.repoid}-${repoContext.branch}-${this.props.file.path}`;
        if (!ace) {
            toast({
                message: "Unable to load editor! Please check your internet connection",
                intent: "danger"
            });
            return;
        }
        this.editorObj = ace.edit(this.editorDiv);
        repoContext.registerAce(itemFullyQualifiedID, this.editorObj);
        this.editorObj.setOptions(this.state.options);
        this.editorObj.getSession().on("changeAnnotation", () => { printAnnotations(this.editorObj) });
        this.editorObj.on("change", this.proccessContentChanges);
        this.editorObj.commands.addCommand({
            name: 'save',
            bindKey: {win: "Ctrl-s", "mac": "Cmd-s"},
            exec: function (editor: any){
                //console.log("Saving...", editor);
                idb.saveFile(itemFullyQualifiedID);
            }
            });
        let mlist = ace.require("ace/ext/modelist");
        if (mlist) {
            let mode = mlist.getModeForPath(this.props.file.path).mode;
            this.editorObj.session.setMode(mode);
        }
        else {
            toast({
                message: "Unable to load ace/ext/modelist",
                intent: "danger"
            });
        }
        this.interval = setInterval(this.syncSettings, 100);
        this.syncSettings();
        this.loadBlob();
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }
    render() {
        let display = this.props.file.id === this.context.activeTab ? "block" : "none";
        let editorStyle = {
            width: "inherit",
            height: "inherit",
            overflow: "hidden",
            margin: 0,
            display: this.state.loading ? "none" : "block"
        }
        return (
            <div style={{ width: "100%", height: "100%", display: display, overflow: "hidden" }}>
                <pre
                    style={editorStyle}
                    ref={(r) => this.editorDiv = r}
                >
                    {this.content}
                </pre>
                {this.state.loading && <Loading shrink style={{ position: "absolute" }} />}
            </div>
        );
    }
}
