import React from "react";
import Login from "../pages/Login.js";
import Loading from "../pages/Loading.js";
import {PageContext} from "./PageContext";

class SessionLoader extends React.Component {
  static contextType = PageContext;
  constructor(props) {
    super(props);
    this.state = {
      loggedin: !(-1 === document.cookie.indexOf("PORTACODESESSIONID"))
    };
    this.update = this.update.bind(this);
  }
  update(){
    this.setState({
      loggedin: !(-1 === document.cookie.indexOf("PORTACODESESSIONID"))
    });
  }
  componentDidMount(){
    this.interval = setInterval(this.update, 500);
    this.context.loadUser();
  }
  componentWillUnmount(){
    clearInterval(this.interval);
  }
  render(){
      if(this.state.loggedin || this.context.isLocalhost){
        if(!this.context){
          return (
            <Loading>
              <h1>Hang on</h1>
              <p>Loading page context...</p>
            </Loading>
          )
        }
        if(typeof(this.context.user) === "object"){
          return this.props.children;
        }
        else {
          return (
            <Loading>
              <h1>Hang on</h1>
              <p>The page is loading...</p>
            </Loading>
          )
        }
      }
      else{
        return (<Login />);
      }
  }
}

export default SessionLoader;
