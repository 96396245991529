import React, { useContext } from "react";
import { Resizable, ResizableItem } from "../../components/outline/Resizable";
import { PageContext } from "../../components/PageContext";
import Scrollable from "../../components/scrollable/Scrollable3";
import { EditorView } from "../../components/code";

export default function () {
  const context = useContext(PageContext);
  return (
    <Resizable vertical={true}>
      <ResizableItem color={1}>
        <EditorView darktheme={context.darktheme} />
      </ResizableItem>
      <ResizableItem
        color={2}
        sensorLocation="top"
        resizable={true}
        togglable={true}
        initialSize={(context.height - 50) * 0.3}
      >
        <Scrollable style={{ height: "100%" }}>
          <div id="problems-div">
            <div style={{margin: "5vw"}}>
            <h4>No problems detected</h4>
            <p>Any syntax errors or problems with the code will appear here if Portacode supports error detection for the file's programming language.</p>
            </div>
          </div>
        </Scrollable>
      </ResizableItem>
    </Resizable>
  );
}
