import React from "react";
import {ContextMenu, Menu, MenuDivider, MenuItem } from "@blueprintjs/core";
import * as idb from "../../db/db-functions";

const FileContext = (props: any) =>{
    const {darktheme, node, context} = props;
    return (
        <div className={darktheme? "bp3-dark" : ""} style={{display: "content"}}>
        <Menu className={darktheme? "bp3-dark" : ""}>   
            <MenuItem text="Open" />
            <MenuItem text="Rename" />
            <MenuItem text="Delete" />
            {
                node.className === 'unsaved' && 
                [<MenuItem key={1} text="Discard Changes" onClick={() => {
                    idb.remove('repo-content-unsaved', node.id).then(() => {
                        var tabid:number = context.tabs.findIndex((file: any) => file.id === node.id);
                        if(tabid !== -1){
                            var ace = context.tabs[tabid].ace;
                            ace.session.setValue('');
                            idb.getFile(node.id).then((f:any) => {
                                ace.session.setValue(atob(f.blob.content), 1);
                            });
                        }
                    });
                }} />,
                <MenuItem key={2} text="Save Changes" onClick={() => idb.saveFile(node.id)} />]
            }
            {
                node.className === 'uncommitted' &&
                [<MenuItem key={1} text="Discard Changes" onClick={() => {
                    idb.remove('repo-content-uncommitted', node.id).then(() => {
                        var tabid:number = context.tabs.findIndex((file: any) => file.id === node.id);
                        if(tabid !== -1){
                            var ace = context.tabs[tabid].ace;
                            ace.session.setValue('');
                            idb.getFile(node.id).then((f:any) => {
                                ace.session.setValue(atob(f.blob.content), 1);
                            });
                        }
                    });
                }} />,
                <MenuItem key={2} text="Save Changes" onClick={() => idb.saveFile(node.id)} />]
            }
            <MenuDivider />
            <MenuItem text="Cut" />
            <MenuItem text="Copy" />
            <MenuItem text="Paste" />
        </Menu>
        </div>
    )
}

const FolderContext = (props: any) =>{
    const {darktheme, node, context} = props;
    return (
        <div className={darktheme? "bp3-dark" : ""} style={{display: "content"}}>
        <Menu className={darktheme? "bp3-dark" : ""}>   
            <MenuItem icon="search-around" text="New File" />
            <MenuItem icon="search" text="New Folder" />
            <MenuItem icon="graph-remove" text="Find in Folder" />
            <MenuItem icon="group-objects" text="Copy Path" />
            <MenuDivider />
            <MenuItem icon="group-objects" text="Cut" />
            <MenuItem icon="group-objects" text="Copy" />
            <MenuItem icon="group-objects" text="Paste" />
        </Menu>
        </div>
    )
}

const ItemContextMenu = (node: any, nodePath: number[], e: any, context: any) => {
    const darktheme = window.localStorage.getItem("theme") !== "light";
    const isDir:boolean = node.type === 'tree';
    console.log(node)
    e.preventDefault()
    ContextMenu.show(
        isDir ? <FolderContext darktheme={darktheme} node={node} context={context}/> : <FileContext darktheme={darktheme} node={node} context={context}/>,
        { left: e.clientX, top: e.clientY },
        () => {
            console.log("Context menu closed")
            node.isSelected = false;
        },
    );
    console.log("Context menu opened", e.target);
}
export {ItemContextMenu};