import React from "react";
//import Logo from "../icons/scanning-with-pins-nofill.svg";
import { loading } from "../icons/loading.js";
import { PageContext } from "../components/PageContext";

class Loading extends React.Component {
  static contextType = PageContext;
  render() {
    let style = {
      textAlign: "center",
      fontSize: 25,
      padding: "15%",
      width: "100%",
      height: "100%",
      ...this.props.style
    };
    let imgStyle = {};
    if(!this.props.shrink){
      style["position"] = "fixed";
    }
    else{
      imgStyle = {position: "absolute", top: "calc( 50% - 100px )", left: "calc( 50% - 100px )"};
    }
    var classN = window.localStorage.getItem("theme") === "light" ? "light-1" : "dark-1";
    if (typeof (this.context) === "object" && typeof (this.context.darktheme) !== "undefined") {
      classN = this.context.darktheme ? "dark-1" : "light-1";
    }
    return (
      <div
        className={classN}
        style={style}
      >
        <img src={loading} style={{ width: 200, ...imgStyle }} alt="Portacode-logo" />
        <p></p>
        <div>{this.props.children}</div>
        {this.props.debug ? (
          <pre style={{ textAlign: "left", border: "1px solid" }}>
            {JSON.stringify(this.context, null, 2)}
          </pre>
        ) : (
            ""
          )}
      </div>
    );
  }
}

export default Loading;
