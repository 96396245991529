import React from "react";
import { ReactComponent as Logo } from "../icons/small-noanimation.svg";
import { PageContext } from "./PageContext";
import {MainMenu} from "./MainMenu.js";

import {
  Navbar,
  Alignment,
  Button,
  Popover,
  Icon,
  Classes,
} from "@blueprintjs/core";

/**
 * this.props.user ={
 *    id, handle, name, scope, badge, picture
 * }
 */

class Nav extends React.Component {
  static contextType = PageContext;

  render() {

    if (true)
      return (
        <Navbar className={this.context.darktheme ? "bp3-dark" : ""}>
          <Navbar.Group align={Alignment.LEFT}>
            <Navbar.Heading>
              <Button
                onClick={()=>(window.location.href="#/")}
                className="bp3-minimal"
                icon={<Logo className={Classes.ICON} width="45" height="45" />}
              />
            </Navbar.Heading>
            <Navbar.Divider />
          </Navbar.Group>
          <Navbar.Group align={Alignment.RIGHT}>
            <Popover 
              autoFocus={false}
              position="bottom"
              content={<MainMenu />}
            >
              <Button
                className="bp3-minimal"
                icon={
                  <img
                    src={this.context.user.picture}
                    className={Classes.ICON}
                    width="35"
                    height="35"
                    alt={this.context.user.username}
                    style={{
                      borderRadius: 50,
                    }}
                  />
                }
                text={<Icon icon="caret-down" />}
              ></Button>
            </Popover>
          </Navbar.Group>
        </Navbar>
      );
  }
}

export default Nav;
