import React from "react";
import { PageContext } from "./PageContext";


class Container extends React.Component <{color?: number, noMargin?: boolean, style?: any, fillHeight?: boolean}>{
  static contextType = PageContext;
  render() {
    var theme = this.context.darktheme ? "dark" : "light";
    var templateClass = this.props.color !== undefined? `${theme}-${this.props.color}` : "" ;
    var paddingSize = 0;
    if (this.context.width < 600) paddingSize = 20;
    else if (this.context.width < 800) paddingSize = 40;
    else paddingSize = 100;
    let style = this.props.noMargin
      ? {
          textAlign: "left",
          ...this.props.style
        }
      : {
          textAlign: "left",
          marginLeft: paddingSize,
          marginRight: paddingSize,
          marginTop: paddingSize,
          marginBottom: paddingSize,
          ...this.props.style
        };
    if (this.props.fillHeight) {
      style.minHeight = "calc( 100vh )";
    }
    return (
      <div data-type="Container" className={templateClass} 
      style={{overflow: "overlay", ...this.props.style}}>
        <div data-type="Container-Inner" style={style}>{this.props.children}</div>
      </div>
    );
  }
}

export default Container;
