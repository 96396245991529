import {DBBluePrint, DBVersion} from "./db-blueprint";

let iDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;
let dbo = {};

var db = iDB.open("portacode-client", DBVersion);

var dbf = {};

var dbPromise = new Promise(
  (resolve) => {
    dbf.resolve = resolve;
  },
  (reject) => {
    dbf.reject = reject;
  }
);

db.onerror = function(event) {
    console.log("Error opening database:");
    console.log(event.srcElement.error);
    dbf.reject(event.srcElement.error);
};

db.onsuccess = function() {
    console.log("Database opened.");
    dbo = db.result;
    dbf.resolve(db.result);
};

/**
 * Takes an objectStore data and creates initializes its structure
 * @param {object} d DBObject
 * @param {object} os objectStore data structure
 */
function importObjectStore(d, os){
    let objectStore = d.createObjectStore(os.name, {keyPath: os.keyPath, autoIncrement: os.autoIncrement});
    os.indexes.forEach(index => {
        objectStore.createIndex(index.name, index.handle, {unique: index.unique});
    });
}

db.onupgradeneeded = function(event) {
  dbo = event.target.result;
  console.log(dbo);
  console.log("Upgrading database from version " 
  + event.oldVersion + " to version " + dbo.version + "...");
  dbo.onerror = function(event) {
    console.log("Error upgrading database:");
    console.log(event);
  };
  Array.from(dbo.objectStoreNames).forEach((name) => {
    dbo.deleteObjectStore(name);
  });
  DBBluePrint.forEach((ObjectStoreBluePrint) =>{
    importObjectStore(dbo, ObjectStoreBluePrint);
  });
  
  console.log("Database updated.");
};

export { db, dbo, dbPromise};
