interface User{
    id: number; // Serial ID on github
    badge?: string; // eg: "admin"
    handle: string; //eg: "meena-erian" 
    name?: string; // eg: "Meena Erian"
    picture?: string; // eg: "https://avatars1.githubusercontent.com/u/22460138?v=4"
    scope?: string; //eg: "repo, user:email"
}

interface RepoList{
    userid: number; // id of the owner of repositories eg: 12345, 
    length: number; // Current number of repos of the specified user eg: 26
    repos:  // list of repositories of the specified user
        {
            default_branch: string; //eg: "master"
            full_name: string; // eg: "meena-erian/guestbook-backend"
            id: number; //repository serial id eg: 249152833
            name: string; //repository name eg: "guestbook-backend"
            node_id: string; //eg: "MDEwOlJlcG9zaXRvcnkyNDkxNTI4MzM="
            private: boolean; // eg: false
        }[]
}

interface RepoMeta{
    id: string; // userid-repoid-branch eg: "12345-249092375-master"
    full_name: string; // username/reponame eg: "meena-erian/guestbook-frontend"
    name: string; // reponame eg: "guestbook-frontend", 
    owner: string; // username eg: "meena-erian", 
    userid: number; // user serial id eg: 12345
    repoid: number; // serial github id of the repository eg: 249092375
    sha: string; // hash code of the repository eg: "39836d65db74d73a91c7d0bb788980f34c2c76e6"
    branch: string; // eg: "master"
    branches:  // list of available branches for this repository
        {
            commit: {
                sha: string; //eg: "fe0680585fd3ff5900ffe3e840452274a6e2a15e", 
                url: string; //eg: "https://api.github.com/repos/meena-erian/guestbook-frontend/commits/fe0680585fd3ff5900ffe3e840452274a6e2a15e"
            }
            name: string; // Branch name eg: "master"
            protected: boolean; // false
        }[]
}

interface RepoTree{
    id: string; // userid-repoid-branch-pathfilename eg: "12345-243602781-master-src/public/.htaccess"
    path: string; // pathfilename eg: "src/public/.htaccess"
    branch: string; // eg: "master"
    dir: string; //parent directory eg: "src/public"
    mode: string; // file mode eg: "100644"
    name: string; //Filename eg: ".htaccess"
    owner: string; //username eg: "meena-erian"
    repoid: number; // repo serial id eg: 243602781
    //reponame: undefined
    sha: string; //file hash code eg: "1a3a91f989f565aa046e6553acd8cdf4ec2369ef"
    size: number; //file size eg: 600
    type: string; //item type (blob or tree) eg: "blob"
    url: string; //api url of the item eg: "https://api.github.com/repos/meena-erian/portacode-framework/git/blobs/1a3a91f989f565aa046e6553acd8cdf4ec2369ef"
    userid: number; //eg: 12345
}

interface RepoContent{
    id: string; // hash code of the blob eg: "1a3a91f989f565aa046e6553acd8cdf4ec2369ef"
    content: string; // base64 encoded file content eg: "CiMgTm9uLXd3dyBmb3IgcG9ydGFjb2RlLmNvbSAmIHJlbW92ZSB0cmFpbGlu↵ZyBzbGFzaGVzClJld3JpdGVDb25kICV7SFRUUF9IT1NUfSAgIF53d3dcLnBv↵cnRhY29kZVwuY29tJCBbTkNdClJld3JpdGVSdWxlIF4oLio↵"
    path: string; // Path/filename eg: "src/public/.htaccess"
    tree: string; //"12345-243602781-master"
}

interface RepoContentUnsaved{
    id: string; // userid-repoid-branch-pathfilename eg: "12345-249092375-master-src/components/LoginPage.js"
    userid?: number; // owner id eg: 12345
    repoid?: number; // repo serial id eg: 243602781
    branch?: string; // eg: "master"
    content: string; // base64 encoded content eg: "aW1wb3J0IFJlYWN0IGZyb20gInJlYWN0IjsKCmNsYXNzIExvZ2luUGFnZSBleHRlbmRzIFJlYWN0LkNvbXBvbmVudCB7CiAgY29uc3RydWN0b3IocHJvcHMpIHsKICAgIHN1cGVyKHByb3BzKTsKICAgIHRoaXMuc3RhdGUgPSB7CiA=="
    path: string; // Path/filename eg: "src/components/LoginPage.js"
    dir?: string; // Parent directory eg: src/components
    type?: 'blob' | 'tree';
    sha: string; // Hash code of the blob eg: "c9600fb29779ca023353798c4eb253777a0c5abd"
    tree: string; // userid-repoid-branch eg: "12345-249092375-master"
}

interface RepoContentUncommitted{
    id: string; // userid-repoid-branch-pathfilename eg: "12345-249092375-master-src/components/LoginPage.js"
    userid?: number; // owner id eg: 12345
    repoid?: number; // repo serial id eg: 243602781
    branch?: string; // eg: "master"
    content: string; // base64 encoded content eg: "aW1wb3J0IFJlYWN0IGZyb20gInJlYWN0IjsKCmNsYXNzIExvZ2luUGFnZSBleHRlbmRzIFJlYWN0LkNvbXBvbmVudCB7CiAgY29uc3RydWN0b3IocHJvcHMpIHsKICAgIHN1cGVyKHByb3BzKTsKICAgIHRoaXMuc3RhdGUgPSB7CiA=="
    path: string; // Path/filename eg: "src/components/LoginPage.js"
    dir?: string; // Parent directory eg: src/components
    type?: 'blob' | 'tree';
    sha: string; // Hash code of the blob eg: "c9600fb29779ca023353798c4eb253777a0c5abd"
    tree: string; // userid-repoid-branch eg: "12345-249092375-master"
    staged?: boolean;
}

const DBBluePrint = [
    {// User info [type User]
        name: "user",
        keyPath: "id",
        autoIncrement: false,
        indexes: [
            { name: "username", handle: "username", unique: true }
        ]
    },
    {// List of user repositories [type RepoList]
        name: "repo-list",
        keyPath: "userid",
        indexes: [
        ]
    },
    {// Meta data for repositories saved for offline use. [type RepoMeta]
        name: "repo-meta",
        keyPath: "id", // "userid-repoid-branch-pathname"
        indexes: [
            { name: "full_name", handle: "full_name", unique: false }, // /username/reponame
            { name: "location", handle: ["full_name", "branch"], unique: true },
        ]
    },
    {// Meta data of each and every item in all repositories saved locally  [type RepoTree]
        name: "repo-tree",
        keyPath: "id", // "userid-repoid-branch-pathname"
        indexes: [
            { name: "dir", handle: ["userid", "repoid", "branch", "dir"], unique: false }
        ]
    },
    {// This is where code will be stored
        name: "repo-content",
        keyPath: "id", //This will be the per-versoin unique hash code
        indexes: [
            { name: "tree", handle: "tree", unique: false }, // an id field of an entry in "repo-tree"
            { name: "fqi", handle: ["tree", "path"], unique: true} // Fully Qualified Id index
        ]
    },
    {// This is where code will be temporarily stored when changing the content of a repository
        name: "repo-content-unsaved",
        keyPath: "id", // Unlike repo-content, this is: "userid-repoid-branch-pathname"
        indexes: [
            { name: "tree", handle: "tree", unique: false }, // userid-repoid-branch
            { name: "dir", handle: ["userid", "repoid", "branch", "dir"], unique: false }
        ]
    },
    {// This is where a new version of a code file will be saved on the client side before staging and committing changes
        name: "repo-content-uncommitted",
        keyPath: "id",  // Unlike repo-content, this is: "userid-repoid-branch-pathname"
        indexes: [
            { name: "tree", handle: "tree", unique: false }, // userid-repoid-branch
            { name: "dir", handle: ["userid", "repoid", "branch", "dir"], unique: false }
        ]
    }
] as const;
const DBVersion = 12 as const;
const DBObjectSoreNames = ['user', 'repo-list', 'repo-meta', 'repo-tree', 'repo-content', 'repo-content-unsaved', 'repo-content-uncommitted'] as const;
type ObjectStoreName = typeof DBObjectSoreNames[number];

export { DBBluePrint, DBVersion };
export type { ObjectStoreName };
export type { User, RepoList, RepoMeta, RepoTree, RepoContent, RepoContentUnsaved, RepoContentUncommitted };