import React, { useContext } from "react";
import { RepoContext } from "../RepoContext";
import Dir from "./Dir";

interface DirViewProps {
  className?: string;
}

export default function DirView(props: DirViewProps) {
  const context = useContext(RepoContext);
  return (
    <Dir onOpen={context.openFile} className={props.className} />
  )
}
