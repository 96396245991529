import * as dbd from "./db-functions";
import {DBBluePrint} from "./db-blueprint";

let idb = dbd;
console.log(idb);

DBBluePrint.forEach((ObjectStoreBluePrint) =>{
  idb[ObjectStoreBluePrint.name] = {
    add: function (obj) {return dbd.add(ObjectStoreBluePrint.name, obj);},
    get: function (q) {return dbd.get(ObjectStoreBluePrint.name, q);}
  };
});

export default idb;