import React from "react";
import idb from "../db/db.js";
import * as api from "./api-module.js";
import toast from "./AppToaster.js";

let initialValue:any = {}
const PageContext = React.createContext(initialValue);

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === "[::1]" ||
  // 127.0.0.0/8 are considered localhost for IPv4.
  window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  ) ||
  window.location.hostname.match(/^192\.168\.[0-9]{1,3}\.[0-9]{1,3}$/)
);

class PageContextProvider extends React.Component {
  /**
   * this.state.user is initially undefined unless its localhost,
   * in such case it loades default values. Otherwise, it attemts to
   * load it from the indexedDB every half a second
   *
   * An external component is expected to request this.loadUser in order
   * to fetch user info from remote server.
   */
  public interval: any = false;
  public state: any = {
    darktheme: window.localStorage.getItem("theme") !== "light",
    width: window.innerWidth,
    height: window.innerHeight,
    isLocalhost: isLocalhost,
  };
  constructor(props: any) {
    super(props);
    console.log("Server-Side API: ", api);
    console.log("IndexedDB API: ", idb);
    this.toggleTheme = this.toggleTheme.bind(this);
    this.update = this.update.bind(this);
    this.loadUser = this.loadUser.bind(this);
  }
  /**
   * Updates the PageContext's state from the localStorage and
   * the indexedDB.
   */
  async update() {
    this.setState({
      darktheme: window.localStorage.getItem("theme") !== "light",
      width: window.innerWidth,
      height: window.innerHeight,
      tabOpenHandler: null
    });
    let uid: string | number | null = window.localStorage.getItem("currentUser");
    if (!uid) return;
    uid = parseInt(uid);
    if (isNaN(uid)) return;
    let user = await idb.get("user", { id: uid });
    let repos: any = await idb.get("repo-list", { userid: uid });
    if (typeof repos === "object") {
      repos = repos.repos;
      for(let i=0; i< repos.length; i++){
        let meta:any = await idb.get('repo-meta', {full_name: repos[i].full_name});
        if(meta)
          repos[i].saved = true;
      }
    }
    this.setState({
      user: user,
      repos: repos
    });
  }
  componentDidMount() {
    this.interval = setInterval(this.update, 250);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  toggleTheme() {
    let newValue = !this.state.darktheme;
    window.localStorage.setItem("theme", newValue ? "" : "light");
    this.setState({ darktheme: newValue });
  }
  async loadUser() {
    api.getuser().then(
      async (user) => {
        if (typeof user == "object") {
          this.setState({ user: user });
          await idb.add("user", user);
          let cashes = window.caches;
          if (typeof cashes !== "undefined")
            cashes.open("portacode-cashe").then((cashe) => {
              cashe.add(user.picture);
            });
          else console.log("Failed to open cache!");
          window.localStorage.setItem("currentUser", user.id);
        }
      }, (err) => {
        toast({
          intent: "danger",
          message: "Connection failed",
        });
        console.log(err);
      }
    );
    api.repos().then(
      async (repos) => {
        if (typeof repos == "object") {
          this.setState({ repos: repos });
          if (typeof this.state.user !== "undefined" && typeof this.state.user.id === "number") {
            await idb.add("repo-list", { userid: this.state.user.id, repos: repos });
          }
        }
      },
      (err) => {
        toast({
          intent: "danger",
          message: "Connection failed",
        });
        console.log(err);
      }
    );
  }
  render() {

    return (
      <PageContext.Provider
        value={{
          ...this.state,
          toggleTheme: this.toggleTheme,
          loadUser: this.loadUser,
          toast: toast,
        }}
      >
        {this.props.children}
      </PageContext.Provider>
    );
  }
}

export { PageContext, PageContextProvider };
