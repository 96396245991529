import * as React from "react";
import {RepoContext} from "../../../components/RepoContext";
import * as idb from "../../../db/db-functions";
import type {RepoContentUncommitted} from "../../../db/db-blueprint";
import { MenuItem, Menu, MenuDivider, Button, TextArea } from '@blueprintjs/core';
import "./Uncommitted.css";

export default class Uncommitted extends React.Component{
    static contextType = RepoContext;
    constructor(props: any){
        super(props);
        this.update = this.update.bind(this);
        this.commit = this.commit.bind(this);
    }
    state = {
        uncommitted: [],
        staged: [],
        unstaged: []
    }
    async update(){
        var items: any = await idb.getAll(
                'repo-content-uncommitted', 
                'tree', 
                `${this.context.userid}-${this.context.repoid}-${this.context.branch}`
            );
        if(typeof(items) !== 'object') items = [];
        items = items.filter((item:RepoContentUncommitted) => item.type === "blob");
        var unstaged: RepoContentUncommitted[] = [];
        var staged: RepoContentUncommitted[] = [];
        items.forEach((item:RepoContentUncommitted) => {
            if(item.staged)
                staged.push(item);
            else
                unstaged.push(item);
        });
        this.setState({
            uncommitted: items,
            staged: staged,
            unstaged: unstaged
        });
    }
    componentDidMount(){
        this.update();
        setInterval(this.update, 200);
    }
    commit(){
        console.log('Committing');
        console.log(this.state.staged);
    }
    render(){
        return (
            <div>
                <GitMultiSelectWrapper items={this.state.uncommitted}
                    staged={this.state.staged}
                    unstaged={this.state.unstaged}
                    onCommit={this.commit}
                />
            </div>
        );
    }
}

class GitMultiSelectWrapper extends React.Component<{
    items:RepoContentUncommitted[], 
    staged:RepoContentUncommitted[],
    unstaged:RepoContentUncommitted[],
    onCommit:Function
}>{
    constructor(props:any){
        super(props);
        this.handleSearchChange = this.handleSearchChange.bind(this);
    }
    state: {
        q: string
    } = {
        q: ""
    }
    highlightText(text: string, query: string) {
        function escapeRegExpChars(text: string) {
            return text.replace(/([.*+?^=!:${}()|[\]/\\])/g, "\\$1");
        }
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter(word => word.length > 0)
            .map(escapeRegExpChars);
        if (words.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join("|"), "gi");
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0].length;
            const before = text.slice(lastIndex, regexp.lastIndex - length);
            if (before.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text.slice(lastIndex);
        if (rest.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    }
    handleSearchChange(e:any){
        var query = e.target.value;
        this.setState({q:query});
    }
    render(){
        var {staged, unstaged, onCommit} = this.props;
        var staged_filtered = staged, unstaged_filtered = unstaged;
        var words = this.state.q.split(/\s+/).filter(word => word.length > 0);
        if(words.length)
        staged_filtered = staged.filter((item: RepoContentUncommitted) => {
            var found = false;
            words.forEach( word => {
                word = word.toLowerCase();
                if(item.path.toLowerCase().includes(word)){
                    found = true;
                } 
            });
            return found;
        });
        if(words.length)
        unstaged_filtered = unstaged.filter((item: RepoContentUncommitted) => {
            var found = false;
            words.forEach( word => {
                word = word.toLowerCase();
                if(item.path.toLowerCase().includes(word)){
                    found = true;
                } 
            });
            return found;
        });

        const StagedChanges = <>
            {staged_filtered.length !== 0 && <MenuDivider title="Staged Changes" />}
            {
                staged_filtered.map((item:RepoContentUncommitted) => 
                <MenuItem 
                    text={this.highlightText(item.path, this.state.q)} 
                    key={item.id}
                    onClick={async () => {
                        var targ:any = await idb.get('repo-content-uncommitted', {id: item.id});
                        targ.staged = false;
                        await idb.add('repo-content-uncommitted', targ);
                    }}
                    icon="document-open"
                />
            )}
        </>;
        
        const UnstagedChanges = <>
            {unstaged_filtered.length !== 0 && <MenuDivider title="Unstaged Changes" />}
            {
                unstaged_filtered.map((item:RepoContentUncommitted) => 
                <MenuItem 
                    text={this.highlightText(item.path, this.state.q)} 
                    onClick={async () => {
                        var targ:any = await idb.get('repo-content-uncommitted', {id: item.id});
                        targ.staged = true;
                        await idb.add('repo-content-uncommitted', targ);
                    }}
                    key={item.id}
                    icon="document"
                />
            )}
        </>;

        const CommitBox = <TextArea
            growVertically={true}
            large={true}
            fill={true}
            className="commit-message-box"
            placeholder="Add commit message..."
            disabled={staged.length === 0}
        />;

        const SearchBox = <div className="bp3-input-group .modifier">
            <span className="bp3-icon bp3-icon-search"></span>
            <input 
                className="bp3-input unstagged-search" 
                onChange={this.handleSearchChange}
                type="search" 
                placeholder="Search files" 
                dir="auto" 
            />
        </div>;

        const CommitButton = <Button 
            disabled={staged.length === 0} 
            minimal={false} 
            fill={true} 
            rightIcon="git-commit" 
            onClick={()=> onCommit()}>
        Commit {staged.length} {staged.length > 0? 'item' : ''}{staged.length > 1? 's' : ''}
        </Button>;

        return (
            <Menu className="uncommitted-container">
                {SearchBox}
                {UnstagedChanges}
                {StagedChanges}
                {CommitBox}
                {CommitButton}
            </Menu>
        );
    }
}