
import { MenuItem } from "@blueprintjs/core";
import { ItemPredicate, ItemRenderer } from "@blueprintjs/select";
import * as React from "react";

export interface Branch {
    name: string;
    default: boolean;
    current: boolean;
    id: number;
}

export const renderBranch: ItemRenderer<Branch> = (branch, { handleClick, modifiers, query }) => {
    if (!modifiers.matchesPredicate) {
        return null;
    }
    const text = `${branch.name}`;
    return (
        <MenuItem
            active={modifiers.active}
            //disabled={modifiers.disabled}
            disabled={branch.current}
            label={branch.default? "Default" : ""}
            icon={'git-branch'}
            key={branch.id}
            onClick={handleClick}
            text={highlightText(text, query)}
        />
    );
};

export const filterBranch: ItemPredicate<Branch> = (query, branch, _index, exactMatch) => {
    const normalizedTitle = branch.name.toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
        return normalizedTitle === normalizedQuery;
    } else {
        return `${normalizedTitle}`.indexOf(normalizedQuery) >= 0;
    }
};

function highlightText(text: string, query: string) {
    let lastIndex = 0;
    const words = query
        .split(/\s+/)
        .filter(word => word.length > 0)
        .map(escapeRegExpChars);
    if (words.length === 0) {
        return [text];
    }
    const regexp = new RegExp(words.join("|"), "gi");
    const tokens: React.ReactNode[] = [];
    while (true) {
        const match = regexp.exec(text);
        if (!match) {
            break;
        }
        const length = match[0].length;
        const before = text.slice(lastIndex, regexp.lastIndex - length);
        if (before.length > 0) {
            tokens.push(before);
        }
        lastIndex = regexp.lastIndex;
        tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
    }
    const rest = text.slice(lastIndex);
    if (rest.length > 0) {
        tokens.push(rest);
    }
    return tokens;
}

function escapeRegExpChars(text: string) {
    return text.replace(/([.*+?^=!:${}()|[\]/\\])/g, "\\$1");
}

export const branchSelectProps = {
    itemPredicate: filterBranch,
    itemRenderer: renderBranch,
};
