import React, { useContext, useState, useEffect } from "react";
import { PageContext } from "../../components/PageContext";
import Loading from "../Loading";
import { RepoContextProvider } from "../../components/RepoContext";
import RepoEditor from "./RepoEditor";
import idb from "../../db/db";
import * as api from "../../components/api-module";
import toast from "../../components/AppToaster";


function getRepoHead(repos: any[], repo_full_name: string) {
  let repoIndex = repos
    .map(function (e: any) {
      return e.full_name;
    })
    .indexOf(repo_full_name);
  if (repoIndex === -1) return false;
  return repos[repoIndex];
}

interface RepoEditorLoaderWrapperProps {
  match: {
    params: {
      ownername: string;
      reponame: string;
      branch: string;
      path?: string;
    };
  };
}

export default function RepoEditorLoaderWrapper(
  props: RepoEditorLoaderWrapperProps
) {
  const full_name = `${props.match.params.ownername}/${props.match.params.reponame}`;
  return (
    <RepoEditorLoader
      ownername={props.match.params.ownername}
      reponame={props.match.params.reponame}
      full_name={full_name}
      branch={props.match.params.branch}
      path={props.match.params.path}
      key={`${full_name}/${props.match.params.branch}`}
    />
  );
}

interface RepoEditorLoaderProps {
  ownername: string;
  reponame: string;
  full_name: string;
  branch: string;
  path?: string;
}

function RepoEditorLoader(props: RepoEditorLoaderProps) {
  const context = useContext(PageContext);
  const ownername = props.ownername;
  const reponame = props.reponame;
  const repo_full_name = props.full_name;
  const repoHead = getRepoHead(context.repos, repo_full_name);
  const repoid = repoHead.id;
  const default_branch = repoHead.default_branch
  const branch = props.branch;
  const userid = context.user.id;

  const [loaded, setLoaded] = useState(false);
  const [branches, setBranches] = useState([]);
  const [sha, setSha] = useState("");

  /**
   * Requests the repository tree from the server and
   *  saves it to the indexedDB if found.
   */
  async function getRepoTree() {
    api.openRepo(repo_full_name, branch).then(
      (ret: any) => {
        if (typeof ret === "object" && ret !== null) {
          if (
            idb.saveRepo(
              userid,
              repoid,
              branch,
              repo_full_name,
              ret.tree.tree,
              ret.tree.sha,
              ret.branches
            )
          ) {
            setBranches(ret.branches);
            setSha(ret.tree.sha);
            setLoaded(true);
            console.log("Repo loaded");
          } else {
            toast({
              intent: "danger",
              message: "Failed to save repository meta data to the indexedDB!",
            });
          }
        } else {
          toast({
            intent: "danger",
            message:
              "Server responded with NULL. Either the repository doesn't exist or you have no permission to access it!",
          });
        }
      },
      (err) => {
        console.log(err);
        toast({
          intent: "danger",
          message: "Connection failed",
        });
      }
    );
  }

  /**
   * Checks whether or not the repository was previously saved
   *  to the indexedDB.
   * @return {Promise<false:any>} returns a promise that resolves to
   * the repo-meta if the repository was found in the indexedDB or false otherwise
   */
  async function repo_is_saved() {
    let meta: any = await idb.get("repo-meta", {
      id: `${userid}-${repoid}-${branch}`,
    });
    if (typeof meta === "object") {
      console.log("Repository already saved. Requesting update...");
      return meta;
    } else {
      toast({
        message: "Loading repository info from the server...",
      });
      return false;
    }
  }


  function init(){
    console.log("Initializing repoEditor");
    if (!repoid) {
      toast({
        intent: "danger",
        message: "Repository not found!",
      });
      return ;
    }
    repo_is_saved().then((meta) => {
      if(meta) {
        console.log(meta);
        setBranches(meta.branches);
        setSha(meta.sha);
        setLoaded(true);
      }
    });
    getRepoTree(); 
  }

  useEffect(init, []);

  if (!loaded) {
    return <Loading />;
  }
  return (
    <RepoContextProvider
      userid={userid}
      repoid={repoid}
      branch={branch}
      branches={branches}
      default_branch={default_branch}
      owner={ownername}
      reponame={reponame}
      full_name={repo_full_name}
      sha={sha}
    >
      <RepoEditor
        screenWidth={context.width}
        screenHeight={context.height}
        darktheme={context.darktheme}
      />
    </RepoContextProvider>
  );
}
