import React from "react";
import { RepoContext } from "../RepoContext";
import "./EditorView.css";
import CodeEditor from "./CodeEditor";
import OpenedTabs from "./OpenedTabs";
import StatusBar from "./StatusBar";
import Loading from "../../pages/Loading";

export default class EditorView extends React.PureComponent<{darktheme: boolean}> {
    static contextType = RepoContext;
    render() {
        var theme = this.props.darktheme ? "dark" : "light";

        if(!this.context.activeTab){
            return <Loading shrink style={{position: "absolute"}}/>
        }

        return (
            <div style={{width: "100%", height: "100%"}}>
                <OpenedTabs darktheme={this.props.darktheme}/>
                <StatusBar darktheme={this.props.darktheme}/>
                <div className={`editor-container ${theme}`}>
                    {this.context.tabs.map((file: any) => (
                        <CodeEditor 
                            key={file.id}
                            file={file}
                            darktheme={this.props.darktheme}
                        />
                    ))}
                </div>
            </div>
        );
    }
}
