/**
 * This file exports <Repos /> which renders repo list button 
 *  and retrives the list of user repos from the PageContext
 */


import * as React from "react";
import {PageContext} from "../PageContext";
import { MenuItem } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import {
    repoSelectProps,
    Repo,
} from "./repo";

const RepoSelect = Select.ofType<Repo>();

export class Repos extends React.Component{
    static contextType = PageContext;

    public items = typeof (this.context.repos) !== 'undefined'? this.context.repos : [];

    public render() {
        
        return (
            <RepoSelect
                {...repoSelectProps}
                popoverProps={{
                    minimal: true,
                    usePortal: true,
                    popoverClassName: "repo-list",
                    targetClassName: "repo-list-target",
                    portalClassName: "repo-list-portal",
                    position: "bottom-right",
                    wrapperTagName: "div",
                }}
                className="repo-list-main"
                items={this.items}
                noResults={
                    <MenuItem disabled={true} text="Nothing found." />
                }
                onItemSelect={this.handleValueChange}
            >
                <MenuItem
                    className="bp3-minimal"
                    icon="git-repo"
                    text="Repositories"
                    shouldDismissPopover={false}
                />
            </RepoSelect>
        );
    }

    private handleValueChange = (repo: Repo) => {
        window.location.href = `#/repo/${repo.full_name}/${repo.default_branch}`;
    };
}
