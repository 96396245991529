/**
 *  This file handles the way the list of repositories is  
 *      rendered and managed
 */

import { MenuItem } from "@blueprintjs/core";
import { ItemPredicate, ItemRenderer } from "@blueprintjs/select";
import * as React from "react";

export interface Repo {
    rank: number;
    id: number;
    node_id: string; 
    name: string;
    full_name: string;
    private: boolean;
    saved: boolean;
    default_branch: string;
}

export const renderRepo: ItemRenderer<Repo> = (repo, { handleClick, modifiers, query }) => {
    if (!modifiers.matchesPredicate) {
        return null;
    }
    const text = `${repo.full_name}`;
    return (
        <MenuItem
            style={{opacity: repo.saved? 1: 0.7}}
            active={modifiers.active}
            disabled={modifiers.disabled}
            label={repo.saved? "Saved" : ""}
            icon={repo.private? "lock" : "git-repo"}
            key={repo.id}
            onClick={handleClick}
            text={highlightText(text, query)}
        />
    );
};

export const filterRepo: ItemPredicate<Repo> = (query, repo, _index, exactMatch) => {
    const normalizedTitle = repo.full_name.toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
        return normalizedTitle === normalizedQuery;
    } else {
        return `${normalizedTitle}`.indexOf(normalizedQuery) >= 0;
    }
};

function highlightText(text: string, query: string) {
    let lastIndex = 0;
    const words = query
        .split(/\s+/)
        .filter(word => word.length > 0)
        .map(escapeRegExpChars);
    if (words.length === 0) {
        return [text];
    }
    const regexp = new RegExp(words.join("|"), "gi");
    const tokens: React.ReactNode[] = [];
    while (true) {
        const match = regexp.exec(text);
        if (!match) {
            break;
        }
        const length = match[0].length;
        const before = text.slice(lastIndex, regexp.lastIndex - length);
        if (before.length > 0) {
            tokens.push(before);
        }
        lastIndex = regexp.lastIndex;
        tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
    }
    const rest = text.slice(lastIndex);
    if (rest.length > 0) {
        tokens.push(rest);
    }
    return tokens;
}

function escapeRegExpChars(text: string) {
    return text.replace(/([.*+?^=!:${}()|[\]/\\])/g, "\\$1");
}

export const repoSelectProps = {
    itemPredicate: filterRepo,
    itemRenderer: renderRepo,
};
