import React from "react";
import { RepoContext } from "../../components/RepoContext";
import { Resizable, ResizableItem } from "../../components/outline/Resizable";
import SidePanel from "./SidePanel/SidePanel";
import MainPanel from "./MainPanel";

export default class RepoEditor extends React.Component<any> {
    static contextType = RepoContext;
    render() {
        var ResizableDirViewProps: any = {
            color: 3,
            sensorLocation: "right",
            togglable: true
        };
        if (this.props.screenWidth < 600) {
            ResizableDirViewProps = {
                ...ResizableDirViewProps,
                initialSize: this.props.screenWidth,
                resizable: false
            }
        }
        else {
            ResizableDirViewProps = {
                ...ResizableDirViewProps,
                initialSize: 300,
                minSize: 200,
                maxSize: this.props.screenWidth / 2,
                resizable: true
            }
        }
        return (
            <Resizable style={{ height: "calc( 100vh - 50px )" }}>
                <ResizableItem {...ResizableDirViewProps}>
                    <SidePanel />
                </ResizableItem>
                <ResizableItem>
                    <MainPanel />
                </ResizableItem>
            </Resizable>
        );
    }
}
