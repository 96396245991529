import React from "react";
import idb from "../../db/db";
import { Classes, Tree } from "@blueprintjs/core";
import { RepoContext } from "../RepoContext";
import { ItemContextMenu } from "./ItemContextMenu";
//import "./Dir.scss";
import "../theme-vars.scss";

interface GitItem {
    path: string; //"src/function-template.html", 
    mode: string; // "100644", 
    type: string; //"blob", 
    sha: string; //"0673cd885e676bb5c1518bd1a8e7007d97e19a88",
    size: number; //180,
    status?: string;

    branch: string; // "master"
    dir: string; //"src"
    id: string; //"12345-0-master-src/function-template.html"
    name: string; //"function-template.html"
    owner: string; //"meena-erian"
    repoid: string; //0
    reponame: string; //undefined
    url: string; //"https://api.github.com/repos/meena-erian/reflection-api/git/blobs/0673cd885e676bb5c1518bd1a8e7007d97e19a88"
    userid: number; //12345
}

interface TreeNode {
    path: string; // "src/function-template.html", 
    mode: string; // "100644", 
    type: string; // "blob", 
    sha: string; // "0673cd885e676bb5c1518bd1a8e7007d97e19a88",
    size: number; // 180,
    className: string;

    dir: string; // "src"
    id: string; // "12345-0-master-src/function-template.html"
    label: any; // name: string "function-template.html"
    secondaryLabel?: any;
    nodeData?: any;
    isExpanded?: boolean;

    icon: "folder-close" | "document";
    childNodes?: TreeNode[]
}

class Dir extends React.Component<{ onOpen: any, className?: string, path?: string }> {
    static contextType = RepoContext;
    public path = this.props.path || "";
    private loadTimer: any = 0;
    public state: { nodes: TreeNode[], selectedNode?: any } = { nodes: [] }
    constructor(props: any) {
        super(props);
        this.loadFirstLevelItems = this.loadFirstLevelItems.bind(this);
        this.translateTreeItems = this.translateTreeItems.bind(this);
        this.prepareNextTreeLevel = this.prepareNextTreeLevel.bind(this);
        this.refreshNodes = this.refreshNodes.bind(this);
        this.refreshDirContent = this.refreshDirContent.bind(this);
        this.openContextMenu = this.openContextMenu.bind(this);
    }
    private translateTreeItem(item: GitItem): TreeNode {
        return {
            path: item.path,
            mode: item.mode,
            type: item.type,
            sha: item.sha,
            size: item.size,
            dir: item.dir,
            id: item.id,
            label: item.name,
            icon: item.type === "tree" ? "folder-close" : "document",
            className: item.status ? item.status.toLowerCase() : ""
        };
    }
    private translateTreeItems(items: GitItem[]): TreeNode[] {
        let tree: TreeNode[] = [];
        items.forEach((item) => {
            tree.push(this.translateTreeItem(item));
        });
        return tree;
    }
    async refreshDirContent(nodes:TreeNode[], dir: string){
        var items:any = await idb.listDir(
            this.context.userid,
            this.context.repoid,
            this.context.branch,
            dir
        );
        items = this.translateTreeItems(items);
        var itemsObj:any = {};
        items.forEach((item:any) => {
            itemsObj[item.id] = item;
        });
        for(var i=0; i<nodes.length; i++){
            if(itemsObj[nodes[i].id] && itemsObj[nodes[i].id].className)
                nodes[i].className = itemsObj[nodes[i].id].className;
            if(nodes[i].type === 'tree'){
                if(nodes[i].childNodes !== undefined){
                    nodes[i].childNodes = await this.refreshDirContent(nodes[i].childNodes as TreeNode[], nodes[i].path)
                }
            }
        }
        return nodes;
    }
    async refreshNodes(){
        var items: any = await idb.listDir(
            this.context.userid,
            this.context.repoid,
            this.context.branch,
            ""
        );
        items = this.translateTreeItems(items);
        var itemsObj:any = {};
        items.forEach((item:any) => {
            itemsObj[item.id] = item;
        });
        var currentNodes: any[] = [];
        Object.assign(currentNodes, this.state.nodes);
        for(var i=0; i<currentNodes.length; i++){
            if(itemsObj[currentNodes[i].id] && itemsObj[currentNodes[i].id].className)
                currentNodes[i].className = itemsObj[currentNodes[i].id].className;
            if(currentNodes[i].type === 'tree'){
                currentNodes[i].childNodes = await this.refreshDirContent(currentNodes[i].childNodes, currentNodes[i].path)
            }
        }
        this.setState({nodes: currentNodes});
    }
    async loadFirstLevelItems() {
        let items: any = await idb.listDir(
            this.context.userid,
            this.context.repoid,
            this.context.branch,
            this.path
        );
        if (items === false) {
            console.log("loadFirstLevelItems failed");
            return false;
        }
        if (this.state.nodes && this.state.nodes.length) {
            //console.log("Repos content already loaded");
            clearInterval(this.loadTimer);
            return false;
        }
        let treeItems = this.translateTreeItems(items)
        await this.prepareNextTreeLevel(treeItems);
        this.setState({ nodes: treeItems });
        window.setInterval(this.refreshNodes, 500);
    }
    async prepareNextTreeLevel(items: TreeNode[]): Promise<any[]> {
        let ret: Promise<any>[] = [];
        for (let i = 0; i < items.length; i++) {
            if (items[i].type === "tree") {
                ret.push(
                    idb.listDir(
                        this.context.userid,
                        this.context.repoid,
                        this.context.branch,
                        items[i].path
                    ).then(
                        (sub_items: any) => {
                            items[i].childNodes = this.translateTreeItems(sub_items);
                            return items[i].path;
                        },
                        (err) => {
                            console.log("Error expanding directory", err, items[i]);
                            return items[i].path;
                        }
                    )
                );
            }
        }
        return Promise.allSettled(ret);
    }
    componentDidMount() {
        this.loadFirstLevelItems();
        this.loadTimer = setInterval(this.loadFirstLevelItems, 200);
    }
    openContextMenu(node: any, nodePath: number[], e: any) {
        console.log('Opening Context menu on ', node)
        node.isSelected = true;
        ItemContextMenu(node, nodePath, e, this.context);
    }
    render() {
        return (
            <div onContextMenu={(e:any) => e.preventDefault()} >
                <Tree
                    contents={this.state.nodes}
                    onNodeClick={this.handleNodeClick}
                    onNodeCollapse={this.handleNodeCollapse}
                    onNodeExpand={this.handleNodeExpand}
                    onNodeContextMenu={this.openContextMenu}
                    className={`${Classes.ELEVATION_0} ${this.props.className}`}
                />
            </div>
        );
    }
    private handleNodeClick = (nodeData: any, _nodePath: number[], e: React.MouseEvent<HTMLElement>) => {
        //const originallySelected = nodeData.isSelected;
        if (!e.ctrlKey) {
            //this.forEachNode(this.state.nodes, n => (n.isSelected = false));
            if (nodeData.type === "blob")
                this.props.onOpen(nodeData, _nodePath, e);
        }
        //nodeData.isSelected = originallySelected == null ? true : !originallySelected;
        if (nodeData.type === "tree") {
            if (!nodeData.isExpanded)
                this.prepareNextTreeLevel(nodeData.childNodes)
            nodeData.isExpanded = !nodeData.isExpanded;
        }
    };

    private handleNodeCollapse = (nodeData: any, _nodePath: number[], e: React.MouseEvent<HTMLElement>) => {
        this.handleNodeClick(nodeData, _nodePath, e);
        nodeData.isExpanded = false;
        //this.setState(this.state);
    };

    private handleNodeExpand = (nodeData: any, _nodePath: number[], e: React.MouseEvent<HTMLElement>) => {
        this.handleNodeClick(nodeData, _nodePath, e);
        nodeData.isExpanded = true;
        //this.setState(this.state);
        this.prepareNextTreeLevel(nodeData.childNodes)
    };

    private forEachNode(nodes: any[], callback: (node: any) => void) {
        if (nodes == null) {
            return;
        }

        for (const node of nodes) {
            callback(node);
            if (typeof (node.childNodes) !== "undefined")
                this.forEachNode(node.childNodes, callback);
        }
    }
}
export default Dir;